import React, { useState } from "react";
import { Modal } from "../../../Common/fModal/Modal";
import { post } from "../../../../Model/Network/Config/Axios";
import { handleError, showToast } from "../../../Common/fToast/ToastCallback";

const SyncAsn = ({onClose,reload,item}) =>{
    const [loading,setLoading] = useState(false)
    
    const onSave = () => {
        setLoading(true)
        post('workspace/integration/triggerAsn',
            { id: `${item.id}` },
            (e, r) => {
                if (r) {
                    showToast({
                        type:'success',
                        message:"Sync successful"
                    })
                    reload()
                    onClose()
                } else {
                    handleError(e)
                }
                setLoading(false)
            })
    }

    return(
        <Modal
        title={'Sync Asn'}
        buttonName={'Sync'}
        des="Are you sure wants to sync Asn?"
        close={onClose}
        onSave={onSave}
        show={true}
        stateLoading={loading}
    >

      </Modal>
    )
}
export default SyncAsn