import React from "react";

function Tiles(props) {
	if(!props.card && !props.kanban){
		return  (<></>)
	}
	if(!props.tile && !props.kanban){
		return  (<></>)
	}
	return (
		<div style={{
			display: 'flex',
			marginTop: 17,
			marginRight: 15,
			padding: 5,
			paddingTop:8,
			borderRadius: 12,
			background: "var(--off-white)"
		}}>
			{
				props.tile?<img data-tip="List View" onClick={() => {
					props.setType("list")
				}} alt="list" src={require('./list.png')} style={{
					width: 35,
					height: 35,
					cursor: "pointer",
					padding: 8,
					borderRadius: 8,
					marginRight:11,
					background: props.type === "list" ? 'var(--white-color)' : "var(--off-white)",
				}}/>:undefined
			}
			{
				props.card ? <img data-tip="Card View" onClick={() => {
					props.setType("card")
				}} alt="dashboard" src={require('./menu.png')} style={{
					width: 35,
					height: 35,
					cursor: "pointer",
					padding: 8,
					background: props.type === "card" ? 'var(--white-color)' : "var(--off-white)",
					borderRadius: 8,

				}}/> : undefined}
			{
				props.kanban ? <img data-tip="Kanban View" onClick={() => {
					props.setType("kanban")
				}} alt="kanban" src={require('./kanban (1).png')} style={{
					width: 35,
					cursor: "pointer",
					height: 35,
					background: props.type === "kanban" ? 'var(--white-color)' : "var(--off-white)",
					padding: 8,
					borderRadius: 8,
					marginLeft: 11
				}}/> : undefined
			}
		</div>
	)
}


export {
	Tiles
}
