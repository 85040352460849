export function maskString(input,maskingEnable=false,visibleStart = 0, visibleEnd = 0,maskChar = '*') {
	if(!maskingEnable) return input
    if (typeof input !== 'string') {
       return input
    }

    const length = input.length;
    if(visibleStart+visibleEnd === 0)
        return input
    if (visibleStart + visibleEnd >= length) {
        return input; // No masking needed
    }
    const maskedPart = maskChar.repeat(length - visibleStart - visibleEnd).slice(0,7);
    return input.slice(0, visibleStart) + maskedPart + input.slice(length - visibleEnd);
}