import React from 'react';
import Moment from 'moment';
import {connect} from 'react-redux';
import {PDFExport} from "@progress/kendo-react-pdf";
import Terms from './Common/Terms';
import Vendor from './Common/Vendor';
import Shipping from './Common/ShippingAddress';
import Items from './Common/Items';

class Index extends React.Component {

    items = (procured) => {
        return (
            <div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p style={{
                        paddingLeft: 10,
                        fontWeight: 600
                    }}>Please provide the following materials</p>

                </div>

                <div>
                    <Items items={procured} currency={this.props.data.currency} data={this.props.data} />
                </div>
            </div>
        )
    }

    confidentails = () => {
        return (
            <div style={{
                borderBottom: '1px solid var(--border-color)',
                display: 'flex',
            }}>
                <div style={{
                    flex: 2,
                    borderRight: '1px solid var(--border-color)',
                    padding: '10px 20px',

                }}>
                    <p style={{
                        fontSize: 16,
                        textTransform: 'uppercase',
                        fontWeight: 600
                    }}>
                        Confidentiality
                    </p>
                    <p style={{
                        textAlign: 'justify',
                        paddingTop: 10
                    }}>
                        All information that is revived or derived or accessed by the vendor and /or its skilled manpower shall remain
                        confidential and shall not be disclosed to any third party whatsover provided further that the vendor may disclose
                        such information in compliance of statutory and regulatory requirements o rin compliance with a judicial or regulatory directive
                        with the prior approval of official to the extent possible and allowed by law. The Vendor shall undertake to ensure that the data collected as part of the
                        service delivery shall be treated in confidentiality and the Vendor shall also undertak that the data will not be shared with any
                        third party and that the data will not be misappropriated, retained beyond the period that is defined for the delivery of
                        services
                    </p>
                </div>
                <div style={{
                    flex: 1
                }}>

                </div>
            </div>
        )
    }

    approve = () => {
        let {details} =  this.props.data;
        return (
            <>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    height: 80,
                    borderBottom: '1px solid var(--border-color)'
                }}>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection:'column'
                    }}>
                        <p style={{
                            color:'var(--primary-color)',
                            fontWeight:700,
                            cursor:'pointer'
                        }} onClick={()=>{
                            window.open(`/app/organisation/employee/${details.raisedBy.ownerId}`,'_blank')
                        }}>{details.raisedBy.ownerName}</p>
                        <p style={{
                            fontWeight: 600
                        }}>Prepared By</p>
                    </div>
                    <div style={{flex:3}}>

                    </div>
                </div>
                <div style={{
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 10,
                    fontSize: 16
                }}>
                    Please note that in case any  representative asks you for any kind of favour at the time of issuing the
                    purchase order or releasing the payment please contact Authorized official.
                </div>

            </>
        )
    }

    render() {
        let list = this.props.data;
        let { conditions, vendor, procured,details,billingTo } = this.props.data;
        let companyName = this.props.config.companyName;
        return (
            <div>
                <PDFExport title="Order Document" padding="40" fileName={`Order-${list.orderId}.pdf`} ref={r => this.pdf = r}>
                    <div style={{
                        width: 400,
                        margin: '10px auto',
                        marginTop: 10,
                        marginBottom: 20,
                        textAlign: 'center'
                    }}>
                        <p style={{
                            fontSize: 32,
                            fontWeight: 600
                        }}>{companyName}</p>
                        <p style={{
                            fontWeight: 700
                        }}>{procured.length? procured[0].officeName : '-'}</p>
                        <p>{procured.length ? procured[0].officeAddress : '-'}</p>
                        <p>{procured.length ? procured[0].officeCity : '-'}</p>




                    </div>
                    <div style={{
                        border: '1px solid var(--border-color)'
                    }}>
                        <div style={{
                            height: 50,
                            borderBottom: '1px solid var(--border-color)',
                            backgroundColor: 'rgb(244,244,244)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 600
                        }}>
                            PURCHASE ORDER DOMESTIC
                    </div>

                        <div style={{
                            borderBottom: '1px solid var(--border-color)',
                            height: 100,
                            display: "flex",
                        }}>

                            <div style={{
                                borderRight: '1px solid var(--border-color)',
                                height: 100,
                                flex: 1,
                                padding: 10
                            }}>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>Vendor Card Code</p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5

                                    }}>: {vendor.vendorId}</p>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>Kind Attention </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>:  {vendor.name}</p>

                                </div>



                            </div>
                            <div style={{
                                flex: 1,
                                padding: 10
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{

                                        width: 140,
                                        fontWeight: 600
                                    }}>Purchase Order No </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>: {list.orderId}</p>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>Delivery date </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>: {Moment(procured[0].deliveryDate).format('DD-MM-YYYY')}</p>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>Created date </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>: {Moment(details.createdAt).format('DD-MM-YYYY')}</p>

                                </div>
                            </div>

                        </div>
                        <div style={{
                            borderBottom: '1px solid var(--border-color)',
                            display: 'flex'
                        }}>
                            <div style={{
                                borderRight: '1px solid var(--border-color',
                                flex: 1
                            }}>
                                <Vendor vendor={vendor} />
                            </div>
                            <div style={{
                                flex: 1
                            }}>
                                <Shipping title='Bill To' address={{
                                    name:billingTo ? billingTo.name : '-',
                                    address:billingTo ? billingTo.address : '-',
                                    city:billingTo ? billingTo.city : '-'
                                }} />
                                <Shipping address={{
                                    name:procured.length?procured[0].officeName:'-',
                                    address:procured.length?procured[0].officeAddress:'-',
                                    city:procured.length?procured[0].officeCity:'-'
                                }} />
                            </div>
                        </div>

                        {this.items(procured)}
                        <Terms conditions={conditions} />
                        {this.approve()}
                    </div>
                </PDFExport>
            </div>
        )
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(Index);
