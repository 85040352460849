import React,{useState,useRef} from 'react'
import Idea from "./idea.png";
import {ProgressBar} from "../../../../Common/fProgress";
import PoDocument from "./Documents";
import { FunctionalInput } from '../../../../Common/fInputs';
import { Modal } from '../../../../Common/fModal/Modal';
import { showToast } from '../../../../Common/fToast/ToastCallback';
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';




export default function Render(props) {

	const [podocument,setPodocument] = useState(false);
	let [deleteModal, setDeleteModal] = useState(false);
	let [withdraw, setWithdraw] = useState(false);
	let [reset,setReset] = useState(false);
	let deleteRemarks = useRef();

	let {data} = props;
	let {status} = data;
	let renderStatus = () => {
		if (status === "DRAFT" || status==='ADMINTOUCH' ) {
			return 1;
		}
		if (status === "PENDING" || status==='HOLD') {
			return 2;
		}
		if (status === "VENDOR_ACK") {
			return 3;
		}
		if (status === "GRN") {
			return 4;
		}
		if (status === "INVOICE") {
			return 5;
		}
		if (status === "APPROVAL") {
			return 6;
		}
		if (status === "COMPLETED" || status === "CANCELLED" || status === "FAILED") {
			return 7;
		}
	}
	let text = () => {
		if (status === "PENDING") {
			return "Wait for approval cycle to complete before taking any action.";
		}
		if (status === "DRAFT") {
			return "Generate purchase order and send details to vendor.";
		}
		if (status === "GRN")
			return "We are waiting for the vendor to supply items";
		if (status === "INVOICE")
			return "We are waiting for submission of invoices for this order by vendor.";
		if (status === "APPROVAL")
			return "We are waiting for your input on three way matching.";
		if (status === "COMPLETED" || status === "CANCELLED" || status === "FAILED") {
			return "This order is already completed.";
		}
	}

	const  menu = [
		...(status==='DRAFT' || status === 'ADMINTOUCH' ? [{name: 'Delete', onClick: ()=>  setDeleteModal(true)}]:[]),
	    ...(status === "PENDING"  ? [{name: 'Withdraw', onClick: ()=> setWithdraw(true) }]:[]),
		...(status === "FAILED"   ? [{name: 'Reset to Draft', onClick: ()=> setReset(true) }]:[]),

	 ]

	return (
		<div className="input-container" style={{
			padding: 0,
			marginTop: 30
		}}>
			<div style={{
				borderBottom: '1px solid var(--border-color)',
				color: "var(--text-color)",
				display: 'flex'
			}}>
				<img src={Idea} alt="Idea" style={{
					width: 50,
					height: 50,
					padding: 12,
				}}/>
				<div style={{
					position: 'relative',
					flex: 1,
					borderLeft: '1px solid var(--off-white)',
					flexDirection: 'row',
					padding: "6px 15px",
					display: 'flex',
					justifyContent: 'space-between',
					alignItems:'center'
				}}>
				  <div style ={{flex:1}}>
					<p style={{
						fontSize: 11,
						fontWeight: 400,
						color: "var(--light-color)"
					}}>
						Quick Tip
					</p>
					<p style={{
						fontSize: 11,
						fontWeight: 600,
						color: "var(--text-color)",
						width:'65%',
						overflow:'hidden',
					}}>
						{
							text()
						}
					</p>
				</div>
				<div style={{display:'flex'}}>
					<ReadOnly>
						<div className="btn btn-portlet" style={{
						width: 180,
						marginLeft: 20,
						marginRight: status==='DRAFT' || status === "PENDING" || status === 'ADMINTOUCH' || status==='FAILED'  ? 40 : 0,
						backgroundColor: "var(--text-color)",
						border: '1px solid var(--text-color)',
						fontSize: 11,
						padding:'5px 20px'
						}} onClick={() => {
						setPodocument(true);
						}}>
							View Purchase Order
						</div>
					</ReadOnly>
						{status==='DRAFT' || status === "PENDING" || status === 'ADMINTOUCH' || status === 'FAILED'?
						<ReadOnly>
							<div onClick={(e)=>e.stopPropagation()} className='cards-dropdown'>
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
								</svg>
							</span>
							<div className="dropdown-content">
								<div className='dropdown-items'>
									{
										menu.map((item)=>{
											return (
												<div onClick={(e)=>{e.stopPropagation();item.onClick()}}>
													{item.icon?item.icon:<></>}
													{item.name}
												</div>
											)
										})
									}
								</div>
							</div>
						</div></ReadOnly>: null}
				</div>

				</div>
			</div>
			<div style={{
				padding: 12,
			}}>
				<ProgressBar stages={[
					{title: 'DRAFT'},
					{title: 'APPROVAL/HOLD'},
					{title: 'V.ACK'},
					{title: 'GRN'},
					{title: 'INVOICE'},
					{title: 'MATCHING'},
					{title: status !== "CANCELLED" && status !== "FAILED" ? 'Completed' : "Cancelled"}
				]} active={renderStatus()}/>
			</div>
			{
					podocument ?
						<PoDocument
							{...props}
							close={() => {
								setPodocument(false);
							}}

						/>
						: null
			}
			{
				reset ?
					<Modal show={true}
						   onSave={() => {
							let remarks = deleteRemarks.current.value;
							if (!remarks) {
								showToast({
									type: 'error',
									message: "Please enter the remarks"
								})
								return;
							}
								props.reset({remarks});
								setReset(false);
							}}
						   title={"Reset PO"}
						   des={"Are you sure want to Reset PO"}
						   close={() => {
						        setReset(false)
						   }}
						   style={{
							   save: {
								   width: '100%',
								   height: '45px',
								   display: 'flex',
								   justifyContent: 'center',
								   textTransform: 'uppercase',
								   alignItems: 'center',
								   backgroundColor: 'var(--red-color)',
								   borderColor: 'var(--red-color)'
							   }
						   }}
						   buttonName={"Reset"}
					>
						<FunctionalInput type="text" title="Enter Remarks" ref={deleteRemarks}/>

					</Modal>
					: null
			}

			{
				(deleteModal || withdraw) ?
					<Modal show={true}
						   onSave={() => {
							   let remarks = deleteRemarks.current.value;
							   if (!remarks) {
								   showToast({
									   type: 'error',
									   message: "Please enter the remarks"
								   })
								   return;
							   }
							   setDeleteModal(false)
							   setWithdraw(false)
							   withdraw ? props.withDraw({remarks}) :  props.delete({remarks})
						   }}
						   title={withdraw ? "WithDraw PO" : "Delete PO"}
						   des={withdraw ? "Are you sure want to withdraw PO" : "Are you sure want to delete PO"}
						   close={() => {
						         setDeleteModal(false)
							     setWithdraw(false)
						   }}
						   style={{
							   save: {
								   width: '100%',
								   height: '45px',
								   display: 'flex',
								   justifyContent: 'center',
								   textTransform: 'uppercase',
								   alignItems: 'center',
								   backgroundColor: 'var(--red-color)',
								   borderColor: 'var(--red-color)'
							   }
						   }}
						   buttonName={withdraw ? "Withdraw": "Delete"}
					>
						<FunctionalInput type="text" title="Enter Remarks" ref={deleteRemarks}/>
					</Modal>
					: null
			}
		</div>
	)
}
