import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { ShownSelect2 } from '../Class/Select/index4'
import { get } from '../../../Model/Network/Config/Axios';
import DownArrow from './down-arrow.png'
import CustomFormSearch from './customFormSearch';

const CustomFieldSearch = forwardRef(({masterList,formType,onSelect,clearInput},ref) => {

    const [fieldName, setFieldName] = useState({});
    const [allFieldMaster, setAllFieldMaster] = useState([]);
    const [fieldValue, setFieldValue] = useState();


    const getAllFieldMaster = () => {
        // if master list is coming from parent(in case of modal ex- recurring invoice) dont initiate the api call
        if(masterList) return;
        get(`workspace/customFields`, (e, r) => {
            if (r) {
                r.fieldList = r.fieldList.map(el => ({ label: el.field, value: el.field }));
                setAllFieldMaster(r.fieldList)
                setFieldName(r.fieldList.length>0?r.fieldList[0]:{});
            }
        })
    }

    useEffect(() => {
        getAllFieldMaster();
    }, [])

    useEffect(()=>{
        if(masterList && masterList.length > 0){
            setAllFieldMaster(masterList);
            setFieldName(masterList[0]);
        }
    },[masterList])


    useImperativeHandle(ref,()=>{
        return {
            GET:()=>{
                if(!fieldValue || !fieldName) return false;
                return {
                    type: fieldName.value,
                    value: fieldValue.value
                }
            }
        }
    })

    return (
        <div style={{
            marginTop: 12,
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row'
        }}>
            <div style={{ width: 155, position: 'relative' }}>
                <ShownSelect2 inputStyle={{ textAlign: 'left', zIndex: 1 }} placeholder="Select Filter Type"
                    items={allFieldMaster} select={setFieldName} value={fieldName} />
                <img src={DownArrow} alt="down-arrow" style={{
                    width: 14, height: 14,
                    position: 'absolute', right: 12,
                    top: 12,
                    zIndex: 0
                }} />
            </div>
            <div style={{ width: 400, position: 'relative' }}>
                <CustomFormSearch clearInput={clearInput} onSelect={(val)=>{
                    setFieldValue(val)
                    if(onSelect){
                        onSelect({
                            type: fieldName.value,
                            value: val.value
                        })
                    }
                }} fieldId={fieldName.value} formType={formType} />
            </div>
        </div>
    )
})

export default CustomFieldSearch