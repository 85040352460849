import React from 'react'
import Props from 'prop-types'
import Attachments from "../../../../Common/Components/Attachments";
import Comments from "../../../../Common/Components/Comments";
import {NormalTable} from "../../../../Tables/NormalTable/NormalTable";
import Moment from "moment";
import Title from "../../../../Common/Title";
import AdditionalApprover from './AdditionalApprover';
import { SupportTicket } from '../../../../Common/SupportTicket';
import FileCard from '../../../../Common/FileCards/FileCard';
import ContractList from './ContractList';
import RenewalTracker from './RenewalTracker';
import ReactToolTip from 'react-tooltip';
import POMultipleEmail from './POMultipleEmail';
import useDateFormat from '../../../fCommon/useDateFormat';
import { useSelector } from 'react-redux';
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';

function RightSidebar(props) { 
	let dateFormate = useDateFormat('DD/MM/YYYY')
	let {poPdfDownloadAfterApproval} = useSelector(state=>state.auth.config.plugins);
	let mapTimeLine = (item, index) => {
	let eaObject = item.eaUser ? JSON.parse(item.eaUser) : undefined;		
		return (
			<div
			 key={index} 
			 style={{ padding:12, borderBottom: '1px solid var(--border-color)'}}
			 className="border-bottom fintrip-department-item table-item"
			 >
				<div className="linear-layout horizontal" style={{flex: 1, cursor: 'pointer', alignItems: 'center'}}>
					<div style={{flex: 1}}>
						<p style={{fontWeight: 600,fontSize: 11}}>{item.email?item.email.toUpperCase():""}</p>
						{
							item.owner && item.owner.ownerType === 'EMPLOYEE' ?
								<a target="_blank" href={`/app/organisation/employee/${item.owner.ownerId}`} style={{
									color: 'var(--primary-color)',
									fontSize: 9,
									marginTop: -4
								}} rel="noopener noreferrer">{item.name?item.name:item.owner.ownerName}</a>
								:
								<p style={{
									fontSize: 9,
									color: "var(--light-color)",
									flex: 1,
									width: 150
								}} data-tip={item.name}>{item.name}</p>
						}
						{
							eaObject &&
							<>
								<div style={{ marginTop: '5px', fontSize: 9, }}>Ea-User: <b>{eaObject.ownerName}</b></div>
								<div style={{ marginTop: '5px', fontSize: 9, }}>Ea-UserId: <b>{eaObject.ownerId}</b></div>
							</>
						}
					</div>
					<div style={{
						flex: 1,
						textAlign: 'right',
						fontSize: 11
					}}>
						<p style={{fontWeight: 600, fontSize: 9}}>{item.status}</p>
						<p style={{fontWeight: 600, fontSize: 9}}>
							Sent On : {item.time ? Moment(item.time).format(`${dateFormate} hh:mm a`):'-'} <br/>
							Action : {item.actionTime ? Moment(item.actionTime).format(`${dateFormate} hh:mm a`):Moment(item.time).format(`${dateFormate} hh:mm a`)}
						</p>
					</div>
				</div>
			</div>
		)
	}

	let mapNotifcation = (item, index) => {
		return (
			<div key={index}
				 style={{
					 paddingLeft: 12,
					 paddingRight: 12,
					 paddingBottom: 10,
					 height: 60,
					 border: '1px solid var(--border-color)'
				 }}
				 className="border-bottom fintrip-department-item table-item linear-layout vertical">
				<div className="linear-layout horizontal" style={{flex: 1, cursor: 'pointer', alignItems: 'center'}}>
					<div style={{flex:1}}>
						<p style={{fontWeight: 600,fontSize: 11}}>{item.title}</p>
						<p style={{
							fontSize: 9,
							color: "var(--light-color)",
							flex: 1,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							width: 150
					   	 }}
						 data-tip={item.text}>
							{item.text}
						</p>
						<ReactToolTip place="top" effect="solid">
						 {item.text}
						</ReactToolTip>
					</div>
					<div style={{flex: 1,textAlign: 'right',fontSize: 11}}>
						<p style={{fontWeight: 600, fontSize: 9}}>{ item.actionTime ? Moment(item.actionTime).format(`${dateFormate} hh:mm a`):Moment(item.time).format(`${dateFormate} hh:mm a`)}</p>
					</div>
				</div>
			</div>
		)
	}
	
	return (
		<div id={props.id} style={{
			height: 'calc(100vh - 180px)',
			flex: 2,
			paddingLeft: 6,
			overflowY: 'auto',
		}}>
			<div id={`${props.prefix}.timeline`} style={{margin: 12}}>
				<Title id="timeline" marginLeft={0} title="Timeline"/>
				<div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 12
				}}>
					<NormalTable
						headers={[]}
						data={props.timeline}
						pagination={false}
						mapItem={mapTimeLine}
						headerStyle={{borderBottom: 'none'}}
					/>
				</div>
			</div>
			<AdditionalApprover type={props.type} init={props.init} />
			{
				props.multipleEmail ?
				<POMultipleEmail init={props.init} multipleEmailValue={props.multipleEmailValue} editable={props.editable} status={props.status} />
				:null
			}
			{

				props.renewalTracking ? <RenewalTracker {...props} /> : null
			}
			{
				props.ticket ?
				<div id={`${props.prefix}.timeline`} style={{margin: 12}}>
					<Title id="Notification" marginLeft={0} title="Support Ticket"/>
					<div className="input-container" style={{
							background: "var(--white-color)",
							margin: 0,
							padding: 12
						}}>
						<SupportTicket id={props.id} type={props.type} />
					</div>
				</div>
				:null
			}
			{
				props.notification ?
					<div id={`${props.prefix}.timeline`} style={{margin: 12}}>
						<Title id="Notification" marginLeft={0} title="Notification"/>
						<div className="input-container" style={{
							background: "var(--white-color)",
							margin: 0,
							padding: 12
						}}>
							<NormalTable
								headers={[]}
								data={props.notification}
								pagination={false}
								mapItem={mapNotifcation}
								headerStyle={{borderBottom: 'none'}}
							/>
						</div>
					</div> : undefined
			}
			{
			(props.poCopy && (poPdfDownloadAfterApproval ?  (!(props.data.status==='DRAFT' || props.data.status==='PENDING' || props.data.status==='VENDOR_ACK')) : true)) ?
			<div id={`${props.prefix}.pocopy`} style={{margin: 12}}>
				<Title id="poattachments" marginLeft={0} title="PO Copy sent to vendor"/>
				<div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 12
				}}>
					<FileCard data={{path:props.poCopy}} />
					{props.data.status !== 'PENDING' &&  props.data.status !== 'DRAFT' ? 
					
					<ReadOnly>
						<div style={{
							boxShadow:'rgb(1 1 1 / 2%) 4px 5px 3px',
							background:'rgb(240, 112, 24)',
							cursor:'pointer',
							border:'1px solid var(--border-color)',
							borderRadius:8,
							width:120,
							fontSize:12,
							textAlign:'center',
							padding:'10px 12px',
							color:"#fff",
							marginLeft:20
						}} onClick={props.openPoCopy}>
							Send Copy
						</div>
					</ReadOnly>
					: null}
				</div>
			</div>
			:null
			}
			{
				props.acknowledge?
			  <div id={`${props.prefix}.acknowledge`} style={{margin: 12}}>
				<Title id="poattachments" marginLeft={0} title="Acknowledge From Vendor Side"/>
				<div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 12
				}}>
				<ReadOnly>
				<div style={{
                		boxShadow:'rgb(1 1 1 / 2%) 4px 5px 3px',
                		background:'var(--primary-color)',
                		cursor:'pointer',
                		border:'1px solid var(--border-color)',
                		borderRadius:8,
                		width:120,
                		fontSize:12,
                		textAlign:'center',
                		padding:'10px 12px',
                		color:"#fff",
						marginLeft:20
            		}} onClick={props.onAcknowledge}>
                		Acknowledge
            		</div>
				</ReadOnly>
				</div>
				</div>
				:null
			}
			{
			props.internal ?
			  <div id={`${props.prefix}.attachments`} style={{margin: 12}}>
				<Title id="attachments" marginLeft={0} title="Internal Attachments"/>
				<div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 12
				}}>
					<Attachments 
						fileName={props.fileName}
						multiple={props.multiple}  
						editable={props.editable} 
						attachments={props.privateAttachments}
						uploadAsyncAttachment={props.postAsyncPrivateAttachment}		 
						uploadAttachment={props.postPrivateAttachment} 
						onDelete={props.removePrivateAttachment}/>
				</div>
			</div>
			:null
			}
			
			<div id={`${props.prefix}.attachments`} style={{margin: 12}}>
				<Title id="attachments" marginLeft={0} title="Public Attachments"/>
				<div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 12
				}}>
					<Attachments 
						fileName={props.fileName}
						multiple={props.multiple} 
						editable={props.editable} 
						attachments={props.attachments}
						uploadAttachment={props.postAttachment} 
						uploadAsyncAttachment={props.postAsyncPublicAttachment}
						onDelete={props.removeAttachment}/>
				</div>
			</div>

			{
				props.contract ?
				<ContractList data={props.data} />
				:null

			}
			<div id={`${props.prefix}.comments`} style={{margin: 12}}>
				<Title id="comments" marginLeft={0} title="Comments"/>
				<div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 12
				}}>
					<Comments 
					 editable={props.editable} 
					 comments={props.comments}
					 add={(comment)=>props.addComment(comment,"comment")}/>
				</div>
			</div>
			
			{
				props.publicComment ?
				<div id={`${props.prefix}.comments`} style={{margin: 12}}>
				 <Title id="comments" marginLeft={0} title="Public Comments"/>
				 <div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 12
				 }}>
					<Comments editable={props.editable} comments={props.publicComments}
							  add={(comment)=>props.addComment(comment,"public")}/>
				 </div>
				</div>
				:null
			}		
		</div>
	)
}

RightSidebar.propTypes = {
	attachments: Props.array.isRequired,
	postAttachment: Props.func.isRequired,
	editable: Props.bool.isRequired,
	comments: Props.array.isRequired,
	addComment: Props.func.isRequired,
}


RightSidebar.defaultProps = {
	attachments: [],
	postAttachment: (data) => {
	},
	comments: [],
	addComment: (data) => {

	},
	privateAttachments:[],
	editable: false,
	publicComment:false,
	poCopy:undefined,
	contract:false,
	multiple:false,
	acknowledge:false,
	renewalTracking:false,
	internal:true
}

export default RightSidebar

export {
	RightSidebar
}
