import React from 'react'
import Props from 'prop-types'
import Thumb from "./Thumb";
import Breadcrumb from "./Breadcrumb";
import ButtonOutline from "./ButtonOutline";
import Navigator from "./Navigator";
import { ReadOnly } from './ButtonOutline/ReadOnly';
import { useReadAccessConfig } from '../../Hooks/ReadAccessConfig';

const Tile=React.memo((props)=>{
	let color = props.badgeColor && props.badge && props.badge!=="-";
	let readOnly = useReadAccessConfig();
	return(
		<div onClick={props.onClick} className='tile' style={{borderRadius: '1.2rem',  width:320,  minHeight: '80px', alignItems: 'center', position: 'relative',marginTop:30,...props.style}}>
			{
				props.badge&&props.badge!==''?
					<div className='capsule' style={{fontSize: 10,textTransform:'uppercase', position: 'absolute', borderRadius: '0.6rem 0.6rem 0px 0px', top: -30, left: 20, paddingLeft: '15px', paddingRight: '15px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '10px', marginLeft: 0, border: 0, maxWidth: '200px', background: color ?props.badgeColor:'var(--border-color)', color: color?"var(--force-white)":"var(--text-color)"}}>{props.badge}</div>
					:undefined
			}
			{props.flag?
					<div className='capsule' style={{fontSize: '9px', position: 'absolute', borderRadius: '0.6rem 0.6rem 0px 0px', top: -30, right: '20px', padding: '4px 15px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '10px', marginLeft: 0, border: 0, background: 'var(--red-color)', color: 'var(--force-white)'}}>{props.flag}</div>
				:<></>
			}
			<div>
				{props.top?props.top:undefined}
			</div>
			<div style={{display:'flex',alignItems:'center',minHeight:'70px'}}>
				<div style={{marginTop: '5px'}}>
					<Thumb thumbClick={()=>{
						if(props.thumbClick) {
							props.thumbClick()
						}
					}} thumb={props.thumb} size="L" letter={props.title?props.title[0]:'A'}/>
				</div>
				<div style={{maxHeight: '100%', width: '100%', overflow: 'hidden'}}>
					<div className='tiny-text' >{props.header}</div>
					<div style={{fontSize: '15px',marginBottom:5,fontWeight:600, ...props.noWrap?{}:{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}}>{props.title}</div>
					{props.children}
				</div>
			</div>
			<div>
				{props.renderer?props.renderer():undefined}
				{props.bottom?props.bottom:undefined}
			</div>

			<div className='delete_icon' style={{justifyContent: 'end', position: 'absolute', top:"-10px", right: "10px"}}>
				{
					props.Option?
						<div onClick={(e)=>{e.stopPropagation();props.Option.action()}} className='flex-center' style={{height: '35px',marginLeft: '7px'}}>
							<div className='flex-center' style={{color: 'var(--white-color)', background: 'var(--text-color)', borderRadius: '50px', fontSize: '10px', padding: '4px 14px'}}>
								{props.Option.title}
							</div>
						</div>:undefined
				}
				{
					props.onEdit?
						<ReadOnly>
							<div onClick={(e)=>{e.stopPropagation();props.onEdit()}} className='flex-center' style={{width: '45px', height: '35px',marginLeft: '7px'}}>
								<div className='flex-center' style={{color: 'var(--white-color)', background: '#6c5dd3', borderRadius: '50px', fontSize: '10px', padding: '4px 14px'}}>
									Edit
								</div>
							</div>
						</ReadOnly>:undefined
				}
				{
					props.chip?
						<div  className='flex-center' style={{height: '35px',marginLeft: '7px'}}>
							<div className='flex-center' style={{color: 'var(--white-color)', background: '#6c5dd3', borderRadius: '50px', fontSize: '10px', padding: '4px 14px'}}>
								{props.chip}
							</div>
						</div>:undefined
				}
				{
					props.onDelete?
					   <ReadOnly>
							<div onClick={(e)=>{e.stopPropagation();props.onDelete()}} className='flex-center' style={{width: '35px', height: '35px',marginLeft: '7px'}}>
								<svg style={{fontSize: "22px", color: "var(--text-color)"}} className="bi bi-x-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
								</svg>
							</div>
						</ReadOnly>
						:undefined
				}
			</div>

			{
				props.menu && !readOnly?
				<div onClick={(e)=>e.stopPropagation()} className='cards-dropdown'>
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
							<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
						</svg>
					</span>
					<div className="dropdown-content">
						<div className='dropdown-items'>
							{
								props.menu.map((item)=>{
									return (
										<div onClick={(e)=>{e.stopPropagation();item.onClick()}}>
											{item.icon?item.icon:<></>}
											{item.name}
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
				:<></>
			}

		</div>
	)
})


Tile.propTypes = {
	title : Props.string.isRequired,
	onDelete : Props.func,
	badge:Props.string,
	onEdit:Props.func,
	chip:Props.string,
	onClick:Props.func,
	header:Props.string.isRequired
}

export {
	Tile,Breadcrumb,ButtonOutline,Navigator
}
