import {   guest, plugins, report} from '../Api/config';
import * as Crypto from "../../../Utils/Crypto";
import axios from 'axios';
import {getBaseUrl, Wrapper as DecentralizeWrapper} from './DecentralizeWrapper'
import {Wrapper as DecentralizeBankingWrapper} from './DecentralizeBankingWrapper'
import {Wrapper as DecentralizeAnalytWrapper} from './DecentralizeAnalytWrapper'
import {Wrapper as DecentralizeAuditWrapper} from './DecentralizeAuditWrapper'
import {Wrapper as UrlWrapper} from './UrlWrapper'

let isRefreshing = false;

axios.defaults.timeout = 62500 * 3;
axios.interceptors.response.use(response => {
	return response;
}, function (error) {
	const err = error.response;
	if (err && err.status === 401 && err.config) {
		const originalRequest = err.config;
		if (!isRefreshing) {
			isRefreshing = true;
			getRefreshToken()
				.then(function (success) {
					onRefreshed(success.data.access_token)
				}).catch(err => {
					localStorage.clear()
					window.location.reload();
				});
		}
		return new Promise((resolve, reject) => {
			subscribeTokenRefresh(token => {
				originalRequest.headers['Authorization'] = 'Bearer ' + token;
				resolve(axios(originalRequest));
			});
		});
	} else {
		if (err)
			return Promise.reject(err);
	}
});

axios.interceptors.request.use(async function (config) {
	const token = Crypto.get('token');
	if (token !== null && token !== "") {
		config.headers.Authorization = `Bearer ${token}`;

	}
	return config;
}, function (err) {
	return Promise.reject(err);
});

function getRefreshToken() {
	let refreshtoken = Crypto.get("refresh_token");
	if (!refreshtoken) {
		localStorage.clear()
		window.location.reload();
	}
	return axios.post(`${getBaseUrl()}/auth/token/refresh`, {
		token: refreshtoken
	});
}

let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
}

const onRefreshed = (token) => {
	setTokens(token);
	isRefreshing = false;
	refreshSubscribers = refreshSubscribers.filter(cb => {
		cb(token);
		return false;
	});
	refreshSubscribers = [];
}

const setTokens = (token) => {
	Crypto.set('token', token);
}




const MainApis = new DecentralizeWrapper()
const BankApis = new DecentralizeBankingWrapper()
const AnalyticApi = new DecentralizeAnalytWrapper()
const AuditApis = new DecentralizeAuditWrapper()


const get = MainApis.get;
const post = MainApis.post;
const patch = MainApis.patch;
const deleteApi = MainApis.delete;
const analyticsGetApis = MainApis.get;
const analyticsPostApis = MainApis.post;
const analyticsDeleteApi = MainApis.delete;
const bankingGetApis = BankApis.get;
const bankingPostApis = BankApis.post;
const transferGetApis = MainApis.get;
const transferDeleteApis = MainApis.delete;
const transferPostApis = MainApis.post;

const GuestApis = new UrlWrapper(guest)
const guestGetApis = GuestApis.get;
const guestPostApi = GuestApis.post;
const guestPatchApi = GuestApis.patch;

const ReportApi = new UrlWrapper(report);
const reportGetApis = ReportApi.get;
const reportPostApis = ReportApi.post;

const PluginApis = new UrlWrapper(plugins)
const pluginsGetApi = PluginApis.get;
const pluginsPostApis = PluginApis.post;

// Analytics
const analyticNewPost = AnalyticApi.post;
const analyticNewGet = AnalyticApi.get;

// audit logs
const auditPost = AuditApis.post;
const auditGet = AuditApis.get;




export default axios;
export {
	auditPost,
	auditGet,
	analyticNewPost,
	analyticNewGet,
	axios,
	post,
	get,
	patch,
	deleteApi,
	pluginsGetApi,
	pluginsPostApis,
	analyticsGetApis,
	analyticsPostApis,
	analyticsDeleteApi,
	transferGetApis,
	transferDeleteApis,
	transferPostApis,
	guestGetApis,
	guestPostApi,
	guestPatchApi,
	bankingGetApis,
	bankingPostApis,
	reportGetApis,
	reportPostApis
}
