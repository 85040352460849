import React from 'react'

const SubmitButton = ({onClick}) => {
  return (
    <div onClick={onClick} style={{
        height: '45px',
        width: '45px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        cursor: 'pointer',
        color: 'var(--white-color)',
        backgroundColor: 'var(--primary-color)',
        zIndex: '111',
        marginBottom: '8px',
        marginLeft: '-45px'
    }}>
        <svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
            width="1em" height="1em" fill="currentColor"
            viewBox="0 0 16 16">
            <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
        </svg>
    </div>
  )
}

export default SubmitButton