import React from 'react';
import Moment from 'moment';
import {connect} from 'react-redux';
import {PDFExport} from "@progress/kendo-react-pdf";
import inrInWords from '../../../../../../../Utils/INRToWords';
import TigerLogo from "../../../../fContracts/ContractDetails/Details/tigerLogo.png";

class TigerAnalytics extends React.Component {
    render() {
        let list = this.props.data;
        let { vendor, orderId, details, billingTo, procured, conditions,payments } = this.props.data;
        let quotationId="-";
        if(procured && procured.length>0 && procured[0].quotationId){
            quotationId=procured[0].quotationId;
        }

        let taxObj={
            "0":0,
            "5":0,
            "12":0,
            "18":0,
            "28":0
        }
        let vendor_address="-";
        if(vendor && vendor.address && vendor.address.address){
            vendor_address=vendor.address.address
        }
        let subTotal=0,total=0,roundedTotal=0,rounded=0;
        for(let i=0;i<procured.length;i++){
            subTotal+=procured[i].base;
            taxObj[procured[i].taxSlab]+=procured[i].tax;
        }
        for(const [, value] of Object.entries(taxObj)){
            total+=value;
        }
        total+=subTotal;
        roundedTotal=Math.round(total);
        rounded=roundedTotal-total;
        const entries = Object.entries(taxObj);

        let mapCondition = (condition) => {
            return (
                <tr key={condition.title} style={{textAlign:'left'}}>
                    <div>{condition.title}</div>
                    <div style={{fontSize:'12px'}}
                        dangerouslySetInnerHTML={{
                        __html: condition.content,
                        }}
                    />
                </tr>
            );
        }

        return (
          <div>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <p
                style={{
                  fontWeight: 600,
                  color: "var(--primary-color)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.pdf.save();
                }}
              >
                Download Now
              </p>
            </div>
            <PDFExport
              title="Order Document"
              padding="40"
              fileName={`Order-${list.orderId}.pdf`}
              ref={(r) => (this.pdf = r)}
            >
            <div>
            <table style={{"max-width": "1000px", "width":"1000px","border": "#808080 solid 2px", "marginTop":"20px"}}>
              <tr>
                <td colspan="5" style={{"border-right": "0px"}}>
                  <img src={TigerLogo} alt="" style={{"height":"200px","width":"200px","marginLeft":"-200px"}}/>
                </td>
                <td colspan="5" style={{"border-left": "0px"}}>
                  <p style={{"font-size": "xx-large","margin-top": "10px","font-weight":"400","text-align":"end","margin-right":"50px"}}>PURCHASE ORDER</p>
                </td>
              </tr>
              <tr style={{"border-top": "#808080 2px solid", "height": "60px"}}>
                <td colspan="5" style={{"border": "#808080 1px solid","border-right": "2px solid #808080"}} >
                    <div style={{"display":"flex","flex-direction":"column"}}>
                        <div style={{"display": "flex","gap":"100px", "font-size": "12px", "width":"400px"}}>
                            <p>PO No </p>
                            <p style={{"font-weight": "bold"}}>: {orderId} </p>
                        </div>
                        <div style={{"display": "flex","gap":"90px", "font-size": "12px", "width":"400px"}}>
                            <p>PO Date </p>
                            <p style={{"font-weight": "bold"}}>: {Moment(details.createdAt).format("DD-MMM-YYYY")} </p>
                        </div>
                        <div style={{"display": "flex","gap":"65px", "font-size": "12px", "width":"400px"}}>
                            <p>Quotation ID </p>
                            <p style={{"font-weight": "bold"}}>: {quotationId} </p>
                        </div>
                    </div>
                </td>
                <td colspan="5" style={{"border": "#808080 1px solid","border-left": "2px solid #808080"}}>
                    <div style={{"display": "flex","gap":"100px", "font-size": "12px", "width":"400px"}}>
                        <p>Location </p>
                        <p style={{"font-weight": "bold"}}>:{details.officeName}  </p>
                    </div>
                </td>
              </tr>
              <tr style={{"background-color": "#D3D3D3"}}>
                  <td colspan="5" style={{"border": "#808080 1px solid", "border-right": "2px solid #808080"}} >
                      <div style={{"font-size": "12px"}}>Procured From</div>
                  </td>
                  <td colspan="5" style={{"border": "#808080 1px solid", "border-left": "2px solid #808080"}}>
                      <div></div>
                  </td>
              </tr>
              <tr style={{"border-top": "#808080 2px solid", "font-size": "12px"}}>
                  <td colspan="5" style={{"border": "#808080 1px solid", "border-right": "2px solid #808080"}} >
                      <div style={{"display":"flex","align-items":"start","flex-direction":"column"}}>
                          <p style={{"font-weight":"bold","text-align":"left"}}>{vendor.name}</p>
                          <p style={{"text-align":"left"}}>{vendor_address}</p>
                          <br/>
                          <p style={{"text-align":"left"}}>GSTIN {vendor.gstin}</p>
                          <p style={{"text-align":"left"}}>PAN: {vendor.pan}</p>
                      </div>
                  </td>
                  <td colspan="5" style={{"border": "#808080 1px solid", "border-right": "2px solid #808080"}}>
                      <div style={{"align-items":"left"}}>
                          <p style={{"font-weight":"bold", "text-align":"left"}}>TIGER ANALYTICS INDIA CONSULTING PRIVATE LIMITED</p>
                          <p style={{"text-align":"left"}}>{details.officeAddress}</p>
                          <br/>
                          <p style={{"text-align":"left"}}>GSTIN {billingTo.gstin}</p>
                          <p style={{"text-align":"left"}}>PAN: AAICT8986D</p>
                      </div>
                  </td>
              </tr>
              <tr style={{"background-color": "#D3D3D3","line-height": "0px"}}>
                <td colspan="1" rowspan="2"
                    style={{"width": "40px","font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                    <p style={{"margin": "5px 0px"}}>#</p>
                </td>
                <td colspan="2" rowspan="2" style={{"width": "500px", "font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                    <p style={{"margin": "5px 0px"}}>Item & Description</p>
                </td>
                <td colspan="1" rowspan="2"
                    style={{"font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                    <p style={{"margin": "5px 0px"}}>HSN/SAC</p>
                </td>
                <td colspan="2" rowspan="2"
                    style={{"font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                    <p style={{"margin": "5px 0px"}}>Qty</p>
                </td>
                <td colspan="1" rowspan="2" style={{"font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                    <p style={{"margin": "5px 0px"}}>Rate</p>
                </td>
                <td colspan="2" rowspan="1" style={{"width": "150px", "font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                    <p style={{"margin": "5px 0px"}}>{`${vendor.gstin.slice(0,2)===billingTo.gstin.slice(0,2)?"CGST/SGST":"IGST"}`}</p>
                </td>
                <td colspan="1" rowspan="2" style={{"font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                    <p style={{"margin": "5px 0px"}}>Amount</p>
                </td>
              </tr>
              <tr style={{"line-height": "0px"}}>
                  <td colspan="1" rowspan="1" style={{"width": "25px", "font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                      <p style={{"margin": "5px 0px"}}>%</p>
                  </td>
                  <td colspan="1" rowspan="1" style={{"width": "25px", "font-weight": "700", "border": "1px solid #000", "color":"#303c42", "font-size":"12px", "padding-left": "4px", "text-align": "center"}}>
                      <p style={{"margin": "5px 0px"}}>Amt</p>
                  </td>
              </tr>
              {procured.map((item, index) => {
                  return (
                    <tr style={{"border": "solid 01px #000"}}>
                        <td colspan="1" style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}>{index+1}</td>
                        <td colspan="2"
                            style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}>
                             {item.notes}<br/>
                        </td>
                        <td colspan="1" style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}> {item.hsn}</td>
                        <td colspan="2" style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}> {item.qtty} </td>
                        <td colspan="1" style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}> {item.price} </td>
                        <td colspan="1" style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}>
                          {item.taxSlab}
                        </td>
                        <td colspan="1" style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}>
                             {Number(item.tax).toFixed(2)}
                        </td>
                        <td colspan="1" style={{"border": "solid 1px #000", "padding": "5px", "text-align": "center", "font-size": "12px"}}>
                          {item.base}
                        </td>
                    </tr>
                  );
                })}
                <tr style={{"border-top": "#808080 2px solid", "font-size": "12px"}}>
                    <td colspan="5" style={{"border": "#808080 1px solid", "border-right": "2px solid #808080"}} >
                        <div style={{"text-align":"left"}}>
                            <p>Total In Words</p>
                            <p style={{"font-weight": "bold"}}>{inrInWords(
                              procured.reduce((total, item) => total + item.amount, 0)
                          )}</p>
                        </div>
                    </td>
                    <td colspan="5" style={{"border": "#808080 1px solid", "border-left": "2px solid #808080"}}>
                        <div style={{"margin-left": "100px"}}>
                            <div style={{"display": "flex", "justify-content": "space-between"}}>
                                <p>Sub Total</p>
                                <p>{subTotal}</p>
                            </div>
                            {entries.filter(([, value]) => {
                                return (value!==0);
                            }).map(([key, value], index) => {
                                return (<div key={index} style={{"display": "flex", "justify-content": "space-between"}}>
                                        <p>{`${vendor.gstin.slice(0,2)===billingTo.gstin.slice(0,2)?`CGST/SGST ${key} (${key}%)`:`IGST ${key} (${key}%)`}`}</p>
                                        <p>{Number(value).toFixed(2)}</p>
                                    </div>)
                            })}
                            <div style={{"display": "flex", "justify-content": "space-between"}}>
                                <p>Rounding</p>
                                <p>{Number(rounded).toFixed(2)}</p>
                            </div>
                            <div style={{"display": "flex", "justify-content": "space-between"}}>
                                <p>Total</p>
                                <p>{roundedTotal}</p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr style={{"border-top": "#808080 2px solid", "height": "150px", "font-size": "12px"}}>
                    <td colspan="5" style={{"border": "#808080 1px solid", "border-right": "2px solid #808080"}} >
                        <div style={{"margin-top": "-50px","text-align":"left"}}>
                            <p>MSME Compliances:</p>
                            <p style={{"text-align":"left"}}>
                                1. Every year on or before 31st March, MSME declaration to be furnished to consider your entity as MSME
                                <br/>
                                2. Invoice should be submitted within 10 days from data of invoice otherwise the said invoice will be cancelled</p>
                        </div>
                    </td>
                    <td colspan="5" style={{"border": "#808080 1px solid", "border-left": "2px solid #808080"}}>
                        <img draggable="false" onContextMenu={(e)=>e.preventDefault()} src={"https://static.eka.io/v2/receipts/2024/05/388412e7-370e-4ad7-9a92-a9c149f9dd9b.png"} alt="Signature" style={{"width": "300px", "height": "150px"}}/>
                        <div style={{"display": "flex", "justify-content": "center"}}>Authorised Signature</div>
                        <p>Venugopal Kode</p>
                        <p>Director - Finance</p>
                    </td>
                </tr>
                <tr style={{"border-top": "#808080 2px solid", "font-size": "12px"}}>
                    <td colspan="10" style={{"border": "#808080 1px solid", "border-right": "2px solid #808080"}} >
                            <p style={{"text-align":"left", "margin-top":"20px", "font-weight":"bold"}}>Payment Terms:</p>
                            {payments.length > 0 ?
                            payments.map(mapCondition) :
                            <p></p>}
                    </td>
                </tr>
                <tr style={{"border-top": "#808080 2px solid", "font-size": "12px"}}>
                    <td colspan="10">
                        <p style={{"text-align":"left", "margin-top":"20px", "font-weight":"bold"}}>PO Terms and Condition:</p>
                        {conditions.length > 0 ?
                            conditions.map(mapCondition) :
                            <p></p>}
                    </td>
                </tr>
              </table>
              <footer>
                <div style={{"font-weight": "bold", "font-size": "13px", "text-align": "center", "color":"#ff8d00", "margin-top": "20px"}}>
                    <p>TIGER ANALYTICS INDIA CONSULTING PVT LTD</p>
                    <p>(CIN: U74999TN2021FTC146673)</p>
                    <p>RMZ Millenia Business Park-2,Campus 5(2nd Floor) No. 143, Kandanchavadi,</p>
                    <p>MGR Road, Perungudi, Chennai 600096. www.tigeranalytics.com</p>
                </div>
            </footer>
            </div>
            
            </PDFExport>
          </div>
        );
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(TigerAnalytics);
