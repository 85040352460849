import React, {useState,useEffect} from 'react'
import {HalfModal} from "../../../../Common/fModal/HalfModal";
import {get} from "../../../../../Model/Network/Config/Axios";
import {PageLoader} from "../../../../Common/Components/PageLoader";
import Attachments from "../../../../Common/Components/Attachments";
import {NormalTable} from "../../../../Tables/NormalTable/NormalTable";
import Moment from "moment";
import Comments from "../../../../Common/Components/Comments";
import {numberWithCommas} from "../../../../../Utils/NumberToComma";

export default function RfqModal(props) {
	const {id} = props;
	let [data,setData] = useState({});
	const [loading,setLoading] = useState(true)
	let [attachments,setAttachments] = useState([]);
	let [comments,setComments] = useState([]);
	let [timelines,setTimeline] = useState([])
	let mapTimeLine = (item, index) => {
		return (
			<div key={index}
				 style={{
					 padding:12,
					 borderBottom: '1px solid var(--border-color)'
				 }}
				 className="border-bottom fintrip-department-item table-item"
			>
				<div className="linear-layout horizontal"
					 style={{flex: 1, cursor: 'pointer', alignItems: 'center'}}
				>
					<div style={{
						flex: 1
					}}>
						<p style={{
							fontWeight: 600,
							fontSize: 11
						}}>{item.email.toUpperCase()}</p>
						{
							item.owner && item.owner.ownerType === 'EMPLOYEE' ?
								<a target="_blank" href={`/app/organisation/employee/${item.owner.ownerId}`} style={{
									color: 'var(--primary-color)',
									fontSize: 9,
									marginTop: -4
								}} rel="noopener noreferrer">{item.owner.ownerName}</a>
								:
								<p style={{
									fontSize: 9,
									color: "var(--light-color)",
									flex: 1,
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									width: 150
								}} data-tip={item.name}>{item.name}</p>
						}

					</div>
					<div style={{
						flex: 1,
						textAlign: 'right',
						fontSize: 11
					}}>
						<p style={{fontWeight: 600, fontSize: 9}}>{item.status}</p>
						<p style={{fontWeight: 600, fontSize: 9}}>{Moment(item.time).format('DD/MM/YYYY hh:mm a')}</p>
					</div>
				</div>
			</div>
		)
	}
	useEffect(()=>{
		get(`vendor/pr/requests/${id}/details`, (e, r) => {
			setData(r)
			setAttachments(r.attachments)
			setTimeline(r.timelines)
			setComments(r.comments)
			setLoading(false)
		});
	},[])
	let mDetails = (key, value) => {
		return (
			<div key={key} style={{
				marginTop: 15,
				flex: 1
			}}>
				<p style={{
					fontSize: 11,
					color: 'var(--light-color)',

				}}>
					{key}
				</p>
				<p style={{
					fontSize: 13,
					color: "var(--text-color)",
					marginTop: 4,
					fontWeight: 600
				}}>
					{value ? value : "-"}
				</p>
			</div>
		)
	}
	if(loading){
		return  (
			<HalfModal
				title="Details of selected quotation appears below"
				desc="Purchase Quotation"
				show={true}
				close={props.close}
				container={{
					left: '50%'
				}}
			>
				<PageLoader/>
			</HalfModal>
		)
	}
	return (
		<HalfModal
			title="Details of selected quotation appears below"
			desc={data.id}
			show={true}
			close={props.close}
			container={{
				left: '50%'
			}}
		>
			<div onClick={()=>{
				window.open(`/app/vendor/procurement/indent/${id}/details`)
			}} style={{
				border :'1px solid #dedede',
				borderRadius:8,
				padding:'12px 16px',
				fontSize:13,
				cursor:'pointer',
				display:'inline-flex'
			}}>
				↱ Open in new tab
			</div>
			<p style={{
				fontSize:15,
				fontWeight:600,
				marginTop:40,
				marginBottom:20
			}}>
				Details
			</p>
			<div>
				{
					mDetails("Status", data.status)
				}
				{
					mDetails("Predicted Amount", `${data.currency} ${numberWithCommas((data && data.currencyAmount)?data.currencyAmount:data.grossAmount)}`)
				}
				{
					mDetails("Creation Date", Moment(data.details.createdAt).format("DD MMM YYYY"))
				}
				{
					mDetails("Raised By", data.details.raisedBy.ownerName)
				}
			</div>
			<p style={{
				fontSize:15,
				fontWeight:600,
				marginTop:40,
				marginBottom:20
			}}>
				Items
			</p>
			{
				data.items.map(item=>{
					return (
						<div style={{
							display:'flex',
							border:'1px solid #DEDEDE',
							padding:'10px 8px',
							fontSize:13
						}}>
							<p style={{
								flex:2
							}}>
								{item.name}
							</p>
							<p style={{
								flex:2
							}}>
								{item.address}
							</p>
							<p style={{
								flex:1
							}}>
								{item.qtty}
							</p>
						</div>
					)
				})
			}
			<p style={{
				fontSize:15,
				fontWeight:600,
				marginTop:40,
				marginBottom:20
			}}>
				Timelines
			</p>
			<NormalTable
				headers={[]}
				data={timelines}
				pagination={false}
				mapItem={mapTimeLine}
				headerStyle={{
					borderBottom: 'none'
				}}
			/>
			<p style={{
				fontSize:15,
				fontWeight:600,
				marginTop:40,
				marginBottom:20
			}}>
				Attachments
			</p>
			<Attachments editable={false} attachments={attachments}/>
			<p style={{
				fontSize:15,
				fontWeight:600,
				marginTop:40,
				marginBottom:20
			}}>
				Comments
			</p>
			<Comments editable={false} comments={comments}/>
		</HalfModal>
	)
}
