import React from "react";
import {connect} from "react-redux";
import Validator from "validator";
import {FloatInput} from "../../Components/Common/Class/FloatLabelInput";
import {showToast} from "../../Components/Common/fToast/ToastCallback";
import './animate.css'
import RecaptchaV3 from '../../Components/Common/RecaptchaV3'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import axios, {get} from "../../Model/Network/Config/Axios"
import Back from './back.jpg'
import Logo from './logo.png'
import {encryptPassword} from "../../Utils/Crypto"
import { AUTHURL } from "../../Model/Network/Api/config";
import SSOLOGIN from './SSO';
import querystring from 'querystring';
class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
	}

	state = {
		on: false,
		logo:Logo,
		name: undefined,
		password: false,
		color:"var(--blue-color)",
		header: <div>Spend <br/>Management.</div>,
		message:"Digital corporate spending & consumption platform,\n" +
			"\t\t\t\t\t\tAI driven employee spending and travel with unparallel experience, control, speed and savings upto 25%.",
		login_message: undefined,
		sso:false,
		ssoScreen:false,
		email:undefined,
		employeeCode:false
	}

	componentDidMount() {

		if(window.location.href.includes('vrts.dice.enterprises')) {
			this.setState({
				...this.state,
				employeeCode:true
			})
		}
		get('auth/customize/home',(e,r)=>{
			if(r){
				this.setState({
					...this.state,
					color:r.label.color,
					logo:r.label.logo,
					...r.label.login
				})
			}
		},{code:window.location.origin})

		let params = new URLSearchParams(this.props.location.search).get('success');
		if(params==='false'){
			this.setState({
				...this.state,
				login_message: new URLSearchParams(this.props.location.search).get('message')
			})
		}
		
	}

	attemptSsoLogin = () => {
		localStorage.clear();
		let email = this.mEmail.value;
		if(!email) {
			showToast({
				type:'error',
				message:"Please enter the email"
			})
			return;
		}
		let data = {
			username:email,
			client_id:"2;3iu4h2p3948p782o3ux8927grp981237r6fo128e973862te7y7286t4xb2ct3b278643230987rgf"
		}
		axios.post(
			"https://provider.dice.tech/auth/admin/username",
			querystring.stringify(data),
			{headers:{
				"Content-Type":"application/x-www-form-urlencoded"
			}}
		).then(res=>{
			if(res && res.data && res.data.ssoUrl) {
				window.location.href = `${res.data.ssoUrl}?email=${email}&relayState=${data.client_id}`
			} else {
				showToast({
					type:'error',
					message:'SSO Login is not enabled for your account'
				})
			}
		})

	}

	attemptLogin = () => {
			localStorage.clear();
			let email = this.mEmail.value;
			let pass = this.mPass.value;

			if(!this.state.employeeCode) {
				if (!Validator.isEmail(email)) {
					showToast({
						type: 'error',
						message: "Please enter a valid email address"
					})
					return;
				}
			}
			if(this.state.employeeCode){
				const companyCode = this._company && this._company.value;
				if(!companyCode){
					showToast({
                        type: 'error',
                        message: "Please enter client code"
                    })
                    return;
				}
				if(!email){
					showToast({
						type: 'error',
						message: "Please enter employee code"
					})
					return;
				}
				email = `${companyCode}|${email}`
			}
			if (!pass || pass.length < 4) {
				showToast({
					type: 'error',
					message: "Please enter a valid password"
				})
				return;
			}

			const form = document.createElement('form');
			form.method = "post";
			form.action = `${AUTHURL}`

			let params =  {
				username:email,password:encryptPassword(email,pass),referrer:window.location.origin
			}
			for (const key in params) {
				if (params.hasOwnProperty(key)) {
					const hiddenField = document.createElement('input');
					hiddenField.type = 'hidden';
					hiddenField.name = key;
					hiddenField.value = params[key];
					form.appendChild(hiddenField);
				}
			}
			document.body.appendChild(form);
			form.submit();
		
	}


	handleEnterLogin = (e) => {
		if (e.key === "Enter" && !this.state.sso) {
			this.attemptLogin();
		}
	}


	sendLink = () => {
		let email = this.mEmail.value();
		if (email) {
			this.presentor.sendResetLink({
				email
			})
		} else {
			showToast({
				type: 'error',
				message: "Please enter a valid email address"
			})
		}
	}

	render() {
		if (this.props.auth.authenticated) {
			const search = this.props.location.search
			const params = new URLSearchParams(search);
			const foo = params.get('next');
			if (foo) {
				this.props.history.push(foo)
			} else
				this.props.history.push("/app/organisation/offices")
		}
		return (
			<div onKeyPress={this.handleEnterLogin} style={{
				position: 'relative',
				background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)) center / cover no-repeat, url('${Back}') center / cover no-repeat`,
				width: '100%',
				height: '100vh',
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
			}}>
				<div className="FadeLeft-Effect" style={{
					flex:1,
					margin:'auto',
					paddingRight:125,
					maxWidth: '700px'
				}}>
					<img src={this.state.logo} alt="logo" style={{
						width:150
					}}/>
					<h1 style={{
						marginTop:40,
						fontSize:52,
						fontWeight: 'bold',
						color: '#fff'
					}}>{this.state.header}</h1>
					<p style={{
						fontSize:16,
						color:"#fff",
						marginTop:20,
						letterSpacing:.5,
					}}>
						{this.state.message}
					</p>
					<div onClick={()=>window.location.href = 'https://www.dice.tech'} style={{color: '#fff', marginTop: '20px', cursor: 'pointer', fontSize: '13px', display: 'flex', alignItems: 'center'}}>
						Know More
						<svg style={{marginLeft: '10px', fontSize: '20px'}} className="bi bi-arrow-right-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
							<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
						</svg>
					</div>
				</div>
				<div className="FadeRight-Effect" style={{
					boxShadow:'rgba(0, 0, 0, 0.25) 0px 0.25rem 0.25rem',
					background:'rgba(235,235,235,0.9',
					backdropFilter: 'blur(10px)',
					zIndex:2,
					marginRight:150,
					borderRadius:12,
					padding:'55px 45px',
				}}>
					{
						this.state.password ? window.location.href.includes('vrts.dice.enterprises') ? window.open('https://mylogin.veritasfin.in:8443/DynamicPortal/user/PWResetUserValidation', '_blank')
							:<div style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center'
							}}>
								<h3 style={{
									fontWeight: 700,
									marginTop: 20,
									fontSize: 42,
									color: "var(--text-color)",
									textAlign: 'center'
								}}>
									Reset
								</h3>
								<p style={{
									marginTop: 5,
									color: "var(--light-color)",
									textAlign: 'center'

								}}>
									Fill details below  to send reset link
								</p>
								<div style={{
									position: 'relative',
									marginTop: 35,
									width: 325,
								}}>
								</div>
								<div>
									<FloatInput  inputStyle={{borderRadius: '10px'}} handleRef={(ref) => {
										this.mEmail = ref
									}} type="email" title="Email Address"/>
									<button style={{marginTop: 35,background:this.state.color, border: 'none', boxShadow: 'none', borderRadius: '6px'}} onClick={this.sendLink}
											className={`btn ${!this.props.auth.authenticated ? 'btn-portlet' : 'btn-success'}`}>
										{this.props.auth.authenticated ? 'Redirecting...' : 'SEND RESET LINK'}
									</button>
									<div style={{marginTop:22}}>
										<p onClick={() => {
											this.setState({
												...this.state,
												password: false
											})
										}} style={{
											cursor: 'pointer',
											fontSize: 13,
											marginTop: 30
										}}>
											Already have credentials?
										</p>
									</div>
								</div>
							</div> :
							 this.state.ssoScreen?
								<SSOLOGIN email={this.state.email} />
							 :
							<div style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
								<h3 style={{
									fontSize: 42,
									color: "var(--text-color)"
								}}>
									{this.state.sso ?"SSO Login": this.state.employeeCode?"Code Login":"Login"}
								</h3>
								<div style={{
									marginTop: 5,
									color: "var(--light-color)"
								}}>
									Welcome back, Please login in to account
								</div>
								<div style={{width: 300, marginTop: '40px'}}>

									{
										this.state.sso ?
										<div>
											<FloatInput handleRef={({ref}) => {
												this.mEmail = ref
												}} type="email" title="Email Address"/>
										</div>
										:

									<>
									{this.state.employeeCode && (
										<FloatInput handleRef={({ref}) => {
											this._company = ref
										}} type="text" title={'Client Code'}/>
									)}
									<FloatInput handleRef={({ref}) => {
										this.mEmail = ref
									}} type="email" title={this.state.employeeCode?"Employee Code":"Email Address"}/>
									<FloatInput title="Password" type="password"
												handleRef={({ref}) => this.mPass = ref}/>
									</>
									}
									<GoogleReCaptchaProvider reCaptchaKey="6LdONk0kAAAAAM3aVQuxbR76xPD6w4NLr35Ir8eF">
										<RecaptchaV3 onToken={data=>{
											this.setState({
												...this.state,
												captcha:data
											})
										}}/>
									</GoogleReCaptchaProvider>

									{this.state.login_message?
									<div style={{fontSize: '14px',color: '#dc3545', marginTop: '20px'}}>{this.state.employeeCode?this.state.login_message:'Invalid Credentials'}</div>
									:<></>}
									<button style={{maxWidth: "100%",border:'none', boxShadow: 'none', marginTop: 15,height:50,borderRadius:8,background:this.state.color}} onClick={this.state.sso?this.attemptSsoLogin:this.attemptLogin}
											className={`btn ${!this.props.auth.authenticated ? 'btn-portlet' : 'btn-success'}`}>
										{this.props.auth.authenticated ? 'Redirecting...' : this.state.sso?"NEXT": 'LOGIN'}
									</button>
									<div style={{marginTop:22,fontSize:11,display:'flex',alignItems:"center",fontWeight:500}}>
										<p style={{
											cursor:'pointer'
										}} onClick={()=>{
											this.setState({
												...this.state,
												sso:!this.state.sso
											})
										}}>
											{
												this.state.sso ?
												"Email Login" :"SSO Login"
											}
										</p> 
										

										<p style={{
											marginLeft:2,
											marginRight:2
										}}>
											|
										</p>

										<p style={{
											cursor:'pointer'
										}} onClick={()=>{
											this.setState({
												...this.state,
												sso:false,
												employeeCode:!this.state.employeeCode
											})
										}}>
											{
												!this.state.employeeCode ?
												"Employee Code" :"Email Login"
											}
										</p> 
										




										<p style={{
											marginLeft:2,
											marginRight:2
										}}>
											|
										</p>
										<p onClick={() => {
											this.setState({
												...this.state,
												password: true
											})
										}} style={{
											cursor: 'pointer',
										}}>
											Forgot Your Password?
										</p>
									</div>
								</div>
							</div>
					}
				</div>
			</div>
		)
	}

}

export default connect(state => {
	return {
		auth: state.auth
	}
})(View);
