import ReactDOM from 'react-dom';
import App from './Controller'
import 'leaflet/dist/leaflet.css';
import * as ServiceWorker from './serviceWorker';
import Moment from 'moment';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from "@sentry/react";



let proto = Moment.prototype;
proto.getTime = proto.valueOf;
Moment.prototype = proto;


Sentry.init({
  dsn: "https://89bff06e8b3d61dca006a36f21b681c8@o529761.ingest.us.sentry.io/4508666685882368",
  integrations: [],
});


ReactDOM.render(App, document.getElementById('root'));
ServiceWorker.register({
	onUpdate: reg => {
		const waitingServiceWorker = reg.waiting
		waitingServiceWorker.addEventListener("statechange", event => {
			if (event.target.state === "activated") {
				console.log("Updated")
			}
		});
		waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
	}
})

