import React from 'react'
import {get} from "../../../../Model/Network/Config/Axios";
import {FloatInput, FloatSelect} from "../FloatLabelInput";
import {showToast} from "../../fToast/ToastCallback";
import FindMaster from './Master';
import { FloatFile } from '../FloatLabelInput/FloatFile';
import {MultiselectDropDown} from './MultiSelect';
import MultiMaster from './MultiMaster';
import _ from 'lodash';

class Form extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			forms: [],
			master: this.props.masterKeyValue?{...this.props.masterKeyValue}: {},
			dependentKeyValue: this.props.dependentKeyValue ? this.props.dependentKeyValue:{},
	
		}
	}
	
	
	componentDidUpdate(prev) {
		if(!_.isEqual(this.props.dependentKeyValue,prev.dependentKeyValue)) {
			this.setState({
				...this.state,
				dependentKeyValue:this.props.dependentKeyValue
			})
		}
	}
	componentDidMount() {
		if (this.props.handler) {
			this.props.handler(this)
		}	    
	}

	
	checkDependableCondition = (value) => {
        if(this.state.dependentKeyValue[value.first]===value.second) {
            return true
        }
        return false
    }
    

    checkDependableForm = (item) => {
        if(item.dependentField && Object.keys(item.dependentField).length>0) {
              return this.checkDependableCondition(item.dependentField)
        }
        return true
    }

	checkDecimalPrecision = (value,id)=>{
		if(value && value.indexOf(".")!==-1 && ((value.length)-value.indexOf(".")>4)){
			showToast({
				"type":"error",
				"message":"Maximum 3 decimal digits allowed"
			})
			this[`field:${id}`].value=value.slice(0,value.indexOf(".")+4);
			return;
		}
	}


	setInitialFieldValues = (initialValues) => {
		const form = this.state.forms;
		for (let i = 0; i < form.length; i++) {
		  const field = form[i];
		  const type = field.type;
		  let initialValue = initialValues[field.fieldId];
		  if(!initialValue) {
			initialValue =   initialValues[field.name]
		}

		  if (initialValue !== undefined) {
			if (type === 'TEXT' || type === 'EMAIL' || type === 'NUMBER' || type === 'DATE') {
			  this[`field:${field.id}`].value = initialValue;
			} else {
			  const fieldRef = this[`field:${field.id}`];
			  if (fieldRef && typeof fieldRef.setValue === 'function') {
				fieldRef.setValue(initialValue);
			  }
			}
		  }
		}
	  };

	clear = () => {
		let form = this.state.forms;
		for (let i = 0; i < form.length; i++) {
			let field = form[i];
			this[`field:${field.id}`].value = ""
		}
	}

	getLength = () => {
		return this.state.forms.length;
	}

	getKeyValue = (cb) => {
		let data = {}
		let form = this.state.forms;
		for (let i = 0; i < form.length; i++) {
			let field = form[i];
			let type = field.type;
			let value = undefined
			if(this.checkDependableForm(field)) {
				if(type === 'TEXT' || type==='EMAIL'||type==='NUMBER'||type==='DATE') {
					value = this[`field:${field.id}`].value;
			   } else {
					value = this[`field:${field.id}`].getValue();
			   }
			 } else {
			 }
			 
			if(value){
				data[field.id] = value
			} else if(field.required && Object.keys(field.dependentField).length>0 ) {
                if(this.state.dependentKeyValue[field.dependentField.first]===field.dependentField.second && !value) {
                    showToast({
                        type:'error',
                        message: `${field.name} is marked required`
                    })
                    return
                } else {
                    data[`${field.id}`] = "";
                }
           } else if (field.required) {
				if (!value) {
					showToast({
						type: 'error',
						message: `${field.name} is required`
					})
					return
				}
			} else if(!field.required && !value) {
                data[`${field.id}`] = "";
            }
		}
		cb(data)
	}

	getFieldValePair = (cb) => {
		let data = {}
		let formData = {}
		let form = this.state.forms;
		for (let i = 0; i < form.length; i++) {
			let field = form[i];
			let type = field.type;
			let value = undefined
			if(this.checkDependableForm(field)) {
				if(type === 'TEXT' || type==='EMAIL'||type==='NUMBER'||type==='DATE') {
					value = this[`field:${field.id}`].value;
			   } else {
					value = this[`field:${field.id}`].getValue();
			   }
			 } else {
			 }
			if(value){
				data[field.fieldId] = value
				formData[field.id] = value
			} else if(field.required && Object.keys(field.dependentField).length>0 ) {
                if(this.state.dependentKeyValue[field.dependentField.first]===field.dependentField.second && !value) {
                    showToast({
                        type:'error',
                        message: `${field.name} is marked required`
                    })
                    return
                } else {
                    data[`${field.fieldId}`] = "";
					formData[field.id] = "";
                }
           } else if (field.required) {
				if (!value) {
					showToast({
						type: 'error',
						message: `${field.name} is required`
					})
					return
				}
			} else if(!field.required && !value) {
                data[`${field.fieldId}`] = "";
				formData[field.id] = "";
            }
		}
		
		cb(data,formData)
	}

	getCustomFormKeyValue = (cb) => {
		let data = {}
		let form = this.state.forms;
		for (let i = 0; i < form.length; i++) {
			let field = form[i];
			let type = field.type;
			let value = undefined
			if(this.checkDependableForm(field)) {
				if(type === 'TEXT' || type==='EMAIL'||type==='NUMBER'||type==='DATE') {
					 value = this[`field:${field.id}`].value;
				} else {
				 	value = this[`field:${field.id}`].getValue();
				}
			}

			if(value) {
				data[field.id] = {"value":value,"name":field.name,"key":field.fieldId}
			} else if(field.required && Object.keys(field.dependentField).length>0 ) {
				if(this.state.dependentKeyValue[field.dependentField.first]===field.dependentField.second && !value) {
                    showToast({
                        type:'error',
                        message: `${field.name} is marked required`
                    })
                    return
                } else {
                    data[`${field.id}`] = {"value":"","name":field.name,"key":field.fieldId}
                }
			}else if (field.required) {
				if (!value) {
					showToast({
						type: 'error',
						message: `${field.name} is required`
					})
					return
				}
			} else if(!field.required && !value) {
				data[field.id] = {"value":"","name":field.name,"key":field.fieldId}
            }
		}
		cb(data)
	}

	getForm = (cb) => {
		let data = []
		let form = this.state.forms;
		for (let i = 0; i < form.length; i++) {
			let field = form[i];
			let value = undefined;
			let type = field.type;

			if(this.checkDependableForm(field)) {
				if(type === 'TEXT' || type==='EMAIL'||type==='NUMBER'||type==='DATE') {
					value = this[`field:${field.id}`].value;
		  		 } else {
					value = this[`field:${field.id}`].getValue();
		  	 } 
			} 
			
			if(value) {
				data.push({"value":value,"name":field.name,"key":field.name,id:field.id})
			}
			else if(field.required && Object.keys(field.dependentField).length>0 ) {
				if(this.state.dependentKeyValue[field.dependentField.first]===field.dependentField.second && !value) {
                    showToast({
                        type:'error',
                        message: `${field.name} is marked required`
                    })
                    return
                } else {
					data.push({"value":"","name":field.name,"key":field.name,id:field.id})

                }
			}else if (field.required) {
				if (!value) {
					showToast({
						type: 'error',
						message: `${field.name} is required`
					})
					return
				}
			} else if(!field.required && !value) {
				data.push({"value":"","name":field.name,"key":field.name,id:field.id})
            }
			
			
		}
		cb(data)
	}


	loadForm = (key) => {
		get(`/forms/${key}`, (e, r) => {
			if (r) {
				this.setState({
					...r
				},() =>{
					if(this.props.initialValues &&  Object.keys(this.props.initialValues).length > 0) 
				    this.setInitialFieldValues(this.props.initialValues)
				})
			}
		})
	}

	mapItem = (item,index) => {
		let type  = item.type.toLowerCase();
		if ((type === 'email' || type === 'number' || type === 'text' || type === 'date') && this.checkDependableForm(item)) {
			return (
				<FloatInput
				defaultValue={this.props.preFill ?  this.props.preFill[item.fieldId] : null}
				 onChange={(val)=>{
					if(type==="number"){
						this.checkDecimalPrecision((val&&val.target&&val.target.value)?val.target.value:"",item.id)
					}
				 }}
				 diasblePrev={(item.type.toLowerCase()=== "date" && item.min===1)?true:undefined}
				 maxLength={item.max} 
				 min={item.min} 
				 key={item.id}
				 title={item.name?item.name:item.placeholder} 
				 placeholder={item.placeholder} 
				 type={item.type.toLowerCase()}
				 handleRef={({ref}) => {
						this[`field:${item.id}`] = ref;
		}}/>)
		} else if (type === "select" && this.checkDependableForm(item)) {
			return (
				<div className="full-width">
							<FloatSelect
								defaultValue={this.props.preFill ?  this.props.preFill[item.fieldId] : null}
								firstValue={true}
								key={index}
								title={item.name}
								onChange={(value ) =>{
									this.setState({
										...this.state,
										// isPrefill:false,
										dependentKeyValue:{
											...this.state.dependentKeyValue,
											[item.fieldId]:value
										}
									})
									if(this.props.onChange) {
										this.props.onChange(item,value);
									}
								}}
								items={item.values}
								handleRef={(ref) => this[`field:${item.id}`] = ref}
							/>
							
					</div>
			)
		}  else if (type === "multiselect" && this.checkDependableForm(item)) {
			return (
				<MultiselectDropDown
						key={index}
						title={item.name}
						options={item.values.map(j=>({value:j.value,label:j.key}))}
						handleRef={ref => this[`field:${item.id}`] = ref}
					/>
			)
		} else if(type === 'file' && this.checkDependableForm(item)) {
			return (
				<FloatFile 
						key={index}
						title={item.name}
						required={item.required}
						placeholder={`Select File`}
						handleRef={ref => this[`field:${item.id}`] = ref}
					/>
				)
		} else if(item.type==='MULTIMASTER' && this.checkDependableForm(item)) {
			return (
			   <MultiMaster 
				   key={item.id}
				   master={this.state.master}
				   handleRef={ref => this[`field:${item.id}`] = ref}
				   details={item}
				   fieldId={item.fieldId?item.fieldId:item.name}
				   label={`${item.name} ${item.required?'*':''}`}
				   placeholder={item.placeholder}
				   onUpdate={()=>{

				   }}
			   />
			)
	   }else if (type === 'master' && this.checkDependableForm(item)) {
            return (
				<FindMaster
				key={index}
				title={item.name}
				required={item.required}
				placeholder={`Search the ${item.placeholder}`}
				handleRef={ref => this[`field:${item.id}`] = ref}
				fieldId={item.fieldId?item.fieldId:item.name}
				details={item}
				onUpdate={(i,next) => {
					let master = {...this.state.master};
					let key = next
					master[key] = i[key];
					this.setState({
						...this.state,
						master,
						dependentKeyValue:{
							...this.state.dependentKeyValue,
							[item.fieldId]:i[key]
						}
					})
				   
				}}
				master={this.state.master}
			/>
            )
        }


	}

	render() {
		
		return (
			<div style={{
				marginTop:20,
				marginBottom:10
			}}>
				{this.state.forms.length>0?
				<p style={{margin:'10px 0px',fontSize:16,fontWeight:600}}>
					{this.props.title && this.props.service? 
					`${this.props.title}-${this.props.service} Line Item Field`
					 : this.props.service ? `${this.props.service} Line Item Field` : this.props.label ? this.props.label : 'Additional Details'}</p>:null }
				<p style={{margin:'10px 0px',fontSize:14,fontWeight:300}}>
					{this.state.forms.length > 0 && this.props.desc  ? this.props.desc : null}
				</p>
				{
					this.state.forms.map(this.mapItem)
				}
			</div>
		)
	}

}

export {
	Form
}
