import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { PDFExport } from "@progress/kendo-react-pdf";
import Moment from 'moment';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';
import { PageLoader } from '../../../../../../Common/Components/PageLoader';

function NinjaCart(props) {
  
  const {data, defaultBank} = props
  const pdf = useRef(null);

  let totalBaseAmount = 0;
  let totalTax = 0;
  let {forms}=data
  let Warranty,Specifications,Remarks;
  if(forms && forms.length){
    forms.map((item)=>{
      if(item.id==='warranty' || item.key==='Warranty') Warranty=item.value
      if(item.id==='specifications' || item.key==='Specifications') Specifications=item.value
      if(item.id==='po_remarks' || item.key==='Remarks') Remarks=item.value
      return null;
    })
  }
  data.procured.forEach(val => {
    totalBaseAmount += val.base;
    totalTax += val.tax;
  })

  if(!data || !defaultBank) return <PageLoader/>

  return (
    <div style={{ maxWidth: "900px", margin: "auto" }}>
        <div style={{ textAlign: "right" }} >
            <p
                style={{
                    fontWeight: 600,
                    color: "var(--primary-color)",
                    cursor: "pointer",
                }}
                onClick={() => { pdf.current.save() }}
            >
                Download Now
            </p>
        </div>
        <PDFExport 
            title="Order Document"
            padding="50"
            fileName={`Order-${data.orderId}.pdf`}
            ref={(r) => (pdf.current = r)}
        >
      <div style={{border:'1px solid black'}} >
      <table style={{ maxWidth: "900px", marginBottom: "20px", cellpadding:"0", cellspacing:"0"}}>
        <tbody>
          <tr>
            <td colSpan="4" style={{ padding: 0 }}>
              <img
                style={{ height: "40px", margin: "10px" }}
                src="https://static.eka.io/v2/receipts/2024/09/70451e31-5467-4178-abea-33c889517964.png"
                alt="Logo"
              />
              <h2 style={{ float: "right", margin: "15px", display: "inline" }}>Purchase Order</h2>
            </td>
            <td colSpan="2" style={{ padding: 0 }}>
              <table style={{ margin: 0 }}>
                <tbody>
                  <tr>
                    <td><strong>Doc No</strong></td>
                    <td style={{ textAlign: "right" }}>{data.orderId}</td>
                  </tr>
                  <tr>
                    <td><strong>Doc Date</strong></td>
                    <td style={{ textAlign: "right" }}>{Moment(data.details.createdAt).format('DD-MM-YYYY')}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="3" style={{ padding: 0, verticalAlign: "top", width: "50%", border:'1px solid black', textAlign: "left" }}>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Bill To:</strong>
              <div>
                <span>{data.billingTo.registered_name}</span><br />
                <span>{data.billingTo.address}</span><br />
                <span><strong>GSTIN/UIN :</strong> {data.billingTo.gstin}</span>
              </div>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Ship-To:</strong>
              <div>
                <span>{data.procured[0].officeRegisteredName}</span><br />
                <span>{data.procured[0].officeAddress}</span><br />
                <span><strong>GSTIN/UIN :</strong>  {data.procured[0].officeGstin}</span><br />
              </div>
            </td>
            <td colSpan="3" style={{ padding: 0, verticalAlign: "top", border:'1px solid black', textAlign: "left" }}>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Information</strong>
              <div>
                <span>PAN: AAACZ8597L</span><br />
                <span>GSTIN: {data.details.officeGstin}</span><br />
                <span>Email: admin.procurement@ninjacart.com</span>
              </div>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Vendor Details:</strong>
              <div>
                <span>{data.vendor.name}</span><br />
                <span>{data.vendor.address ? data.vendor.address.address : null}</span><br />
                <span>
                  {data.vendor.address ? data.vendor.address.city : null}  {data.vendor.address ? data.vendor.address.pincode : null} </span><br />
              </div>
              <table>
                <tbody>
                  <tr>
                    <td style={{padding:0,textAlign:'left'}} ><strong>GSTIN:</strong></td>
                    <td style={{padding:0,textAlign:'left'}} >{data.vendor.gstin}</td>
                  </tr>
                  <tr>
                    <td style={{padding:0,textAlign:'left'}}><strong>PAN:</strong></td>
                    <td style={{padding:0,textAlign:'left'}} >{data.vendor.pan}</td>
                  </tr>
                  <tr>
                    <td style={{padding:0,textAlign:'left'}} ><strong>Contact Number:</strong></td>
                    <td style={{padding:0,textAlign:'left'}} >{data.vendor.mobile}</td>
                  </tr>
                  <tr>
                    <td style={{padding:0,textAlign:'left'}} ><strong>E-Mail:</strong></td>
                    <td style={{padding:0,textAlign:'left'}} >{data.vendor.email}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{ border: "none", marginBottom: "20px", padding: 0 }}>
        <thead>
          <tr style={{ backgroundColor: "#c9c9c9" }}>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Description and Notes</th>
            <th>Quantity</th>
            <th>UOM</th>
            <th>Rate (INR)</th>
            <th>Tax Percentage (%)</th>
            <th style={{ textAlign: "right" }}>Amount ({data.currency})</th>
          </tr>
        </thead>
        <tbody>
        {data.procured.map((item, index) => (
          <tr key={index}>
            <td>{item.productCode}</td>
            <td>{item.name}</td>
            <td>{item.description}, {item.notes}</td>
            <td>{item.qtty}</td>
            <td>{item.unit}</td>
            <td>{numberWithCommas(item.price.toFixed(2))} </td>
            <td>{numberWithCommas(item.taxSlab.toFixed(1))+'%'}</td>
            <td style={{ textAlign: "right" }}>{numberWithCommas(item.amount.toFixed(2))}</td>
          </tr>
        ))}
        </tbody>
      </table>
        <table style={{maxWidth: "900px", tableLayout: "fixed", marginBottom: "20px"}} cellPadding="0" cellSpacing="0" width="900px">
          <tr>
            <td colSpan="5" style={{ padding: 0, border:'1px solid black' }}>
                <strong style={{ backgroundColor: "#c9c9c9", display: "block", border: "1px solid black", padding: "5px" }}>Terms and Conditions:</strong>
                <div style={{ fontSize: "9px", textAlign: "left", padding: "5px" }}>
                    {data.conditions.map((item, index) => {
                        return (
                          <div key={index} style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: 'var(--text-color)',
                              padding:'5px'
                          }}>
                              <div style={{
                                  marginLeft: 10,
                                  fontSize:13,
                              }} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
                          </div>
                      )
                    })}
                </div>
                <table style={{ borderCollapse: "collapse" }}>
                    <tr style={{ padding: "5px"}}>
                        <td style={{textAlign: "left" }} ><strong>Payment Terms:</strong></td>
                        <td>
                            {data.payments.map((item, index) => {
                              return (
                                <div key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'var(--text-color)',
                                    padding:'5px'
                                }}>
                                    <div style={{
                                        marginLeft: 10,
                                        fontSize:13,
                                    }} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
                                </div>
                            )})}
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "left" }} ><strong>Delivery Date:</strong></td>
                        <td>{Moment(data.procured[0].deliveryDate).format("DD-MM-YYYY")}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "left" }} ><strong>Warranty:</strong></td>
                        <td>{Warranty}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "left" }} ><strong>Specifications:</strong></td>
                        <td>{Specifications}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "left" }} ><strong>Remarks:</strong></td>
                        <td>{Remarks}</td>
                    </tr>
                </table>
            </td>
            <td colSpan="3" style={{ padding: 0, verticalAlign: "top" }}>
              <table style={{ borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td><strong>Sub Total:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.currency} {totalBaseAmount.toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td><strong>Freight Charge:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.freightCharge}</td>
                  </tr>
                  <tr>
                    <td><strong>Discount %:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.discount}</td>
                  </tr>
                  <tr>
                    <td><strong>Tax Amount:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.currency} {totalTax.toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td><strong>Total:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.currency} {(totalBaseAmount+totalTax).toFixed(1)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      <table>
        <thead>
          <tr style={{ backgroundColor: "#c9c9c9" }}>
            <th colSpan="4" style={{ textAlign: "center" }}>Vendor Bank Account Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{textAlign:'left', width:'20%'}} >Beneficiary Name :</td>
            <td style={{textAlign:'left', width:'30%'}} >{data.vendor.name}</td>
            <td style={{textAlign:'left', width:'25%'}} >Account No :</td>
            <td style={{textAlign:'left', width:'25%'}} >{defaultBank.account}</td>
          </tr>
          <tr>
            <td style={{textAlign:'left', width:'20%'}} >Bank Name :</td>
            <td style={{textAlign:'left', width:'30%'}} >{defaultBank.bankName}</td>
            <td style={{textAlign:'left', width:'25%'}} >IFS Code :</td>
            <td style={{textAlign:'left', width:'25%'}} >{defaultBank.ifsc}</td>
          </tr>
        </tbody>
      </table>
      </div>
      <footer 
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
          textAlign: 'center',
          marginTop: '20px'
        }}>
          This is a computer generated document. No signature is required.
      </footer>
      </PDFExport>
    </div>
  );
}

export default connect((state) => {
  return {
    config: state.auth.config,
  };
}, null)(NinjaCart)