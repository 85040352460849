import React, { useEffect, useState } from 'react';
import { Buttons } from '../../../../Common/fCore/fButtons';
import { KeyValueDetails } from '../../../../Common/fCore/fKeyValue';
import { Owner } from '../../../../Common/fCore/fOwner';
import { Status } from '../../../../Common/fCore/fStatus';
import { FloatInputButton } from '../../../../Common/fInputs';
import { post } from '../../../../../Model/Network/Config/Axios';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';
import Tds from './Tds';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import CustomTags from '../../../fCommon/CustomTags/CustomTags';
import FileCard from '../../../../Common/FileCards/FileCard';
import { StateButton } from '../../../../Common/fStateButton';
import SelectGST from '../../../../Common/fUtils/SelectGST';



const Index = ({categoriesMapping,data,pending,buttons,reload,id,loading}) => {

	const update = (key, value,isForm) => {
		let  data = {}
		if (isForm) {
			data = {
			   key,
			   value
			};
		} else {
			data[key] = value;
		}
		post(`vendor/advances/${id}/update`,data, (e, r) => {
			if (r) {
				reload()
				showToast({
					type: 'success',
					message: 'Field Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const [tdsAmt,setTdsAmt] = useState();
	const [advanceAmt,setAdvanceAmt] = useState();
	const [show,setShow]=useState(false);
	let categoryName="-";
	if(data && !data.categoryName && data.categoryId){
		categoryName=categoriesMapping[data.categoryId];
	}

	useEffect(()=>{
		if(data){
			if(data && data.content && data.content.baseAmount && data.tds){
				let tdsAmount = ((data.content.baseAmount)*(data.tds.tds))/100;
				setTdsAmt(tdsAmount);
				setAdvanceAmt(data.amount-tdsAmount)
			}
		}
	},[data])
	
    return(
        <div>
            
            <Status badges={[
				{
					title:`Status : ${data.status}`,
					background:'var(--primary-color)'
				},
				{
					title:`Amount : INR ${data.amount}`,
					background:"var(--green-color)"
				}
			]}/>
             {
                pending ?
				(loading 
				? <StateButton/>
                : <Buttons buttons={buttons} />)
                :null
             }

             <Owner owner={data.owner} />
             <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px'}}>PO Details</div>

            
            {
            data.po?
            <>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>PO Number:</span> {data.po.poNo}</div>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Remarks:</span> {data.remarks}</div>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Vendor Name:</span> {data.vendor.name}</div>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Vendor Gstin:</span> {data.vendor.gstin}</div>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Vendor Registered Id:</span> {data.vendor.registeredId}</div>
            </>
            :<div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
            }
            <KeyValueDetails header="Advance Details" values={[
				{
					key:'Advance Id',
					value:data.advanceId
				},
                {
					key:'Base Amount',
					value:(data && data.content && data.content.baseAmount) ? numberWithCommas(data.content.baseAmount) :0
				},
				{
					key:'Tax %',
					value:(data && data.content && data.content.taxSlab) ? data.content.taxSlab :0
				},
                {
					key:'Tax Amount',
					value:(data && data.content && data.content.taxAmount) ? numberWithCommas(data.content.taxAmount) :0
				},
                {
					key:'Tds %',
					value:`${data.tds?data.tds.tds:'-'}`
				},
				{
					key:'Tds Amount',
					value:numberWithCommas(tdsAmt)
				},
				{
					key:'Tds Description',
					value:`INR ${data.tds?data.tds.code:'-'}`
				},
				{
					key:'Category',
					value:`${data.categoryName ? data.categoryName :categoryName}`
				},
				{
					key:'Remarks',
					value:`${data.remarks ? data.remarks :'-'}`
				},
				{
					key:'Advance Amount',
					value:numberWithCommas(advanceAmt)
				},
				{
					key:'Office',
					value:data.office?data.office:'-'
				}
				
			]}/>
            <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px'}}>Advance Details</div>            
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Advance Id:</span> {data.advanceId}</div>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Advance Amount:</span> {numberWithCommas(advanceAmt)}</div>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Tds %:</span> {data.tds?data.tds.tds:'-'}</div>
            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Tds description:</span> {data.tds?data.tds.code:'-'}</div>   
			{data && data.status === "APPROVAL" ?
				<>	
					<div style={{marginTop:10}}><strong>Update Details</strong></div>
					<div style={{ display: 'flex' }}>
						<div style={{ width: '50%' }}>
							<FloatInputButton
								onSubmit={(val) => {
									update('base', Number(val))
								}} title='Base Amount '
								placeholder={(data && data.content && data.content.baseAmount) ? data.content.baseAmount : 0}
								disabled={data.status !== 'APPROVAL'}
							/>
						</div>
						<div style={{ width: '50%', paddingLeft: '10px', marginTop: 20 }}>
							<Tds id={id} value={data.tds ? data.tds.tds : 0}
								vendorId={data.vendor && data.vendor.id}
								init={() => {
									reload()
								}}
								disabled={data.status !== 'APPROVAL'}
							/>
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div style={{ width: '50%' }}>
							<FloatInputButton
								onSubmit={(val) => {
									update('amount', Number(val))
								}} title='Advance Amount '
								placeholder={numberWithCommas(advanceAmt)}
								disabled={data.status !== 'APPROVAL'}
							/>
						</div>
						<div style={{ width: '50%', paddingLeft: '10px', marginTop: 5 }}>
							<SelectGST defaultValue={(data && data.content && data.content.taxSlab) ? { label: `${data.content.taxSlab}`, value: `${data.content.taxSlab}` } 
								:
								{ label: `0`, value: `0` }
							   } placeholder="Select Gst (%)" onSelect={(val) => {
								update('taxSlab', Number(val.value))
							}}/>
						</div>
					</div>
					{
						data.forms && data.forms.length > 0 ?
							<>
								<div><strong>Forms</strong></div>
								{data.forms.map((item, index) => {
									return (
										<FloatInputButton
											onSubmit={(val) => {
												update(item.id, val, true)
											}}
											title={item.key}
											placeholder={item.value || '-'}
											disabled={data.status !== 'APPROVAL'}
										/>
									)
								})}
							</>
							: undefined
					}
				</>
				: null
			}
            {
				data.forms.length?
					<KeyValueDetails header="Custom Details" values={data.forms}/>:undefined
			}

			{
				(data.content.attachments && data.content.attachments.length>0)? 
					<div>
						<div
							style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachments
						</div>
						<div style={{
							display: 'flex',
							marginLeft: '-15px',
							flexWrap: 'wrap',
							maxHeight: '250px',
							overflowY: 'scroll'
						}}>
							{
								data.content.attachments.map((file,index) => {
									return (
										<FileCard
											data={{
												title:`${index+1}) ${file.path.slice(0,20)}${file.path.length>20 ? '...' :''}`,
												path:file.path
											}}
										/>
									)
								})
							}
						</div>
					</div>	:null	
			}
			<div style={{border:'1px solid var(--border-color)',padding:12,borderRadius:12,marginTop:20,paddingTop:10}}>
				<div style={{'display':'flex','justifyContent':'space-between'}}>
					<div onClick={() => {setShow((!show))}} style={{fontSize: '14px', fontWeight: 'bold', cursor:'pointer'}}>Custom Tags</div>
					<div style={{'border':'1px solid black', 'borderRadius':'100%', width:'18px',height:'18px','display':'flex','justifyContent':'center', 'alignItems':'center',cursor:'pointer'}} onClick={() => {setShow((!show))}}>
						<img style={{width:12,transform:!show?'rotate(0deg)':'rotate(180deg)'}} src={require('../../../fCommon/ViewCreditNote/India/down.png')} alt="down"/>
					</div> 
				</div>
				{(show)?<div style={{marginLeft:'1rem'}}>
					<CustomTags title={"Vendor Custom Tags"} data={(data && data.vendor && data.vendor.customTags)?data.vendor.customTags:{}}/>
				</div>:null}
			</div>
        </div>
    )
}

export default Index;