
import React, { useEffect, useRef, useState } from 'react';
import { get, post } from '../../../../../Model/Network/Config/Axios';
import { Card } from '../../../../Common/fCards';
import { FunctionalInput } from '../../../../Common/fInputs';
import { Modal } from '../../../../Common/fModal/Modal';
import { NormalTds } from '../../../../Common/fTax/SelectTds/Nomal';
import { showToast } from '../../../../Common/fToast/ToastCallback';
import Title from '../../../../Common/Title';
import Moment from 'moment';
import Empty from '../../../../Common/fCard/Empty';
import { Form } from '../../../../Common/Class/Form';
import { Tile } from '../../../../Common/fCard/Tile';
import {VendorAdvanceDetails} from '../../VendorAdvance/AdvanceDetails';
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';
import SelectGST from '../../../../Common/fUtils/SelectGST';




const Advance = (props) => {

    let [add,setAdd] = useState(false);
    let [data,setData] = useState([]);
    let [requestItems,setRequestItems] =useState([]);
    let [open,setOpen] = useState(false);
    let REMARKS=useRef(),AMOUNT=useRef(),TDS=useRef(),FORM=useRef(),BASE=useRef(),TAX=useRef(),PAYABLE=useRef();
    let currency = props.data.currency;
    let {id} = props;
    let {status,procured} = props.data;
    
    const [gst, setGst] = useState({
      value: 0,
      label: 0
  })



	  let totalAmount = 0;
	  let sumOfRequestedAdvances = 0;
    procured.length > 0 && procured.forEach(val=>totalAmount += val.amount)
    requestItems.length > 0 && requestItems.forEach(val=>sumOfRequestedAdvances += val.amount)
    useEffect(()=>{
       INIT(id)
    },[id])

    useEffect(()=>{
      RequestInit()
    },[])

    let RequestInit = () => {
      let filter = [];
      filter.push(`${id}@po_id`)
      get(`vendor/advances/pending`,(e,r)=>{
        if(r) {
            get(`vendor/advances/past`,(e1,r1)=>{
              if(r1) {
                 setRequestItems([...r1.transfers,...r.transfers])
              }
            },{filter,offset:0})
        }
      },{filter,offset:0})
    }

    useEffect(()=>{
        if(add) {
            FORM.current.loadForm(`VENDOR::ADVANCE::FORM`)
        }
    },[add])
    
    let INIT = (q) => {
        get(`vendor/po/requests/${q}/advances`,(e,r)=>{
            if(r) {
                setData(r.settlements)
            }
        })
    }
    
    let mapCard = (item,index) =>{
        return(
        <Card  key={`${index}`}
            headings={[item.ledgerId]}
            details={[{label: 'Amount', value: item.amount},{label: 'Date', value: Moment(item.createdAt).format(" hh:mm a DD MMM YY")}, {label: 'Remarks', value: item.remarks}, {label: 'settlementType', value: item.settlementType}]}
            onClick={()=>{}}>
          </Card>
        )
    }

    let mapItem = (item,index) =>{
      return(
        <Tile
			     onClick={()=>{
                  setOpen(item)
			    }}
		     title={item.vendor.name} 
         key={index}
			    badge={`INR ${item.amount}`}>
              <p className="tiny-text">
					        Id: {item.advanceId}
			  	    </p>
				      <p className="tiny-text">
					    {item.po?
					<>
						PO Number: {item.po.poNo}
					</>
					:''}
				</p>
        {item.tds && 
         <p className="tiny-text">
					Tds: {item.tds.tds}%/{item.tds.code}
				</p>
        }
			</Tile>
      )
    }

    return (
      <>
        <div style={{ margin: "0px 40px" }}>
          <Title title="All Advance">
            <div style={{ position: "absolute", right: 0, top: -10 }}>
              {status === "GRN" || status === "INVOICE" ? (
                <ReadOnly>
                  <button
                    style={{
                      border: "var(--primary-color)",
                      background: "var(--primary-color)",
                      padding: "8px 20px",
                      color: "var(--white-color)",
                      borderRadius: 4,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAdd(true);
                    }}
                  >
                    Add Advance
                  </button>
                </ReadOnly>
              ) : null}
            </div>
          </Title>
        </div>

        <div>
          {data.length ? (
            <div style={{ display: "flex", flexWrap: "wrap", marginLeft: 20 }}>
              {data.map(mapCard)}
            </div>
          ) : (
            <Empty style={{ marginLeft: "20px" }} />
          )}
        </div>

        <div style={{margin:'0px 40px'}}>
          <Title title="Advance Request" />
          {requestItems.length ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {requestItems.map(mapItem)}
            </div>
          ) : (
            <Empty style={{ marginLeft: "20px" }} />
          )}
        </div>

        <Modal
          title="Add Advance"
          des="Fill up the details"
          close={() => {
            setAdd(false);
          }}
          show={add}
          onSave={() => {
            let remarks = REMARKS.current.value;
            let tds = undefined;
            let base = +BASE.current.value;
            let amount = AMOUNT && AMOUNT.current && +AMOUNT.current.value;
            let tax =  TAX && +TAX.current;

            if (currency === "INR") {
              tds = TDS.current.getValue();
            }
            if(!base) {
              showToast({
                  type:'error',
                  message:"Please enter the base amount"
              })
              return;
          }
            if (!remarks) {
              showToast({
                type: "error",
                message: "Please enter the remarks",
              });
              return;
            }
            if (!tds && currency === "INR") {
              showToast({
                type: "error",
                message: "Please select the tds",
              });
              return;
            }
            let data = {
              amount,
              remarks,
              tds: tds ? tds.id : 0,
              currency,
              tax,
              base,
              taxSlab: Number(gst.value)
            };
            if ((amount && totalAmount) && amount > totalAmount) {
              showToast({
                type: "error",
                message: "Amount should not greater then PO Amount",
              });
            }  else  if((sumOfRequestedAdvances && totalAmount) && sumOfRequestedAdvances > totalAmount) {
              showToast({
                type: "error",
                message: "Sum of Advance Request should not greater then PO Amount",
              });
            } else {
              FORM.current.getKeyValue((forms) => {
                data.forms = forms;
                post(
                  `vendor/po/requests/${id}/advanceV2`,
                  { ...data },
                  (e, r) => {
                    if (r) {
                      setAdd(false);
                      INIT(id);
                      RequestInit()
                      props.init();
                      showToast({
                        type: "success",
                        message: "advance add successfully",
                      });
                    }
                  }
                );
              });
            }
          }}
        >
           <FunctionalInput type="text" title="Base Amount" ref={BASE}
            onChange={(e) => {
              let val = e.target.value
              const baseAmount = Number(val);
              const gstValue = gst.value;
              const tax = (baseAmount / 100) * Number(gstValue)
              TAX.current = (tax).toFixed(2)
              AMOUNT.current.value = (tax + baseAmount).toFixed(2)
              // TDS minus the base amount to get payable amount 
              let tds = TDS.current.getValue() ? TDS.current.getValue().tax : 0;
              const tdsTax = (Number(baseAmount) * Number(tds) / 100)
              PAYABLE.current.value = (Number(baseAmount) + tax - tdsTax).toFixed(2)

            }}
          />
          <SelectGST defaultValue={gst} placeholder="Select Gst" onSelect={(val) => {
            const baseValue = Number(BASE.current.value);
            const gstPercentage = Number(val.value);
            setGst({
              value: gstPercentage,
              label: val.label
            })
            const tax = (Number(baseValue) * gstPercentage / 100)
            TAX.current = (tax).toFixed(2)
            AMOUNT.current.value = (tax + baseValue).toFixed(2)
            // For Tds
            let tds = TDS.current.getValue() ? TDS.current.getValue().tax : 0;
            const tdsTax = (Number(baseValue) * Number(tds) / 100)
            PAYABLE.current.value = (baseValue + tax - tdsTax).toFixed(2)
          }}/>
          {currency === "INR" ? (
            <NormalTds handleRef={(ref) => (TDS.current = ref)} 
            onSelect={(tds) => {
              const baseValue = Number(BASE.current.value);
              const tdsPercentage = tds && tds.tax && tds.tax
              const tdsTax = (Number(baseValue) * Number(tdsPercentage||0) / 100)
              const gstValue = gst.value;
              const gstTax= (baseValue / 100) * Number(gstValue)
              PAYABLE.current.value = (baseValue+gstTax-tdsTax).toFixed(2)
          }}
            />
          ) : (
            <></>
          )}
          <FunctionalInput type="text" title="Total Amount" ref={AMOUNT} disabled />
          <FunctionalInput type="text" title="Total Payable(after tds deduction)" ref={PAYABLE} disabled />
          <FunctionalInput type="text" title="Remarks" ref={REMARKS} />
          {currency === "INR" ? (
            <NormalTds handleRef={(ref) => (TDS.current = ref)} 
            onSelect={(tds) => {
              const baseValue = Number(BASE.current.value);
              const tdsPercentage = tds && tds.tax && tds.tax
              const tdsTax = (Number(baseValue) * Number(tdsPercentage||0) / 100)
              const gstValue = gst.value;
              const gstTax= (baseValue / 100) * Number(gstValue)
              PAYABLE.current.value = (baseValue+gstTax-tdsTax).toFixed(2)
          }}
           />
          ) : (
            <></>
          )}
          <Form handler={(ref) => (FORM.current = ref)} />
        </Modal>
        {
                open ?
                <VendorAdvanceDetails onClose={()=>{setOpen(false)}} data={open}/>:
                null
            }
      </>
    );
}

export default Advance;