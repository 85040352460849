import React, { useState } from 'react';
import CreateRequest from '../../../Others/Payouts/Eka/RechargeWallet/CreateRequest';
import Moment from 'moment';

export const WalletRechargeRequest = ({ invoiceId, dueOn ,reload}) => {
    const [walletRequest, setWalletRequest] = useState(undefined);
    return (
        <>
            <div style={{ display: 'flex', width: '96%' }}>
                <div onClick={() => {
                    setWalletRequest({
                        attrs: {
                            invoice_id: invoiceId
                        },
                    })
                }}
                    className='btn btn-primary'
                    style={{ background: "var(--primary-color)", color: '#fff', width: '100%',flex:0.5}}>
                    Initiate Wallet Recharge
                </div>
                {(dueOn && dueOn > 0 ? Moment(dueOn).isAfter(Moment()) && Moment(dueOn).diff(Moment(), 'days') >= 5 : false) &&
                    <div onClick={() => {
                        setWalletRequest({
                            attrs: {
                                invoice_id: invoiceId
                            },
                        })
                    }}
                        className='btn btn-primary'
                        style={{ background: "#a7c2eb", color: '#fff', width: '100%', maxWidth: '315px', marginLeft: 10 ,flex:0.5}}>
                        Pay D3
                    </div>
                }
            </div>
            {walletRequest && <CreateRequest prefill={walletRequest} onClose={() => setWalletRequest(undefined)} reload={reload} />}
        </>
    )

}