import React, { useState } from 'react'
import Props from 'prop-types'
import { isBoolean } from 'lodash';
import { maskString } from '../../../Utils/MaskString';
import Visible from '../../Common/Components/visible.png'
import Hide from '../../Common/Components/hidden.png'
function FunctionalKeyValue(props) {
   const isPasswordField = props.secureTextEntry;
   const [isPasswordVisible, setPasswordVisible] = useState(false);

	const displayValue = () => {
		if (isBoolean(props.value)) {
			return props.value ? "Yes" : "No";
		}
		if(isPasswordField){
			return <div style={{display:'flex',}}>
			<p
			  className="top"
			  style={{
				fontSize: 12,
				fontWeight: 500,
				letterSpacing: 1,
				color: '#666',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				flex: 1,
			  }}
			>
			  {!isPasswordVisible
				? maskString(props.value,2,3)
				: props.value
				}
			</p>
				{isPasswordField && (
					<button
						onClick={() => setPasswordVisible(c => !c)}
						style={{
							background:'none',
							border: 'none',
							cursor: 'pointer',
							marginLeft: 8,
							color: 'var(--primary-color)',
						}}
						aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
					>
					  <img alt='check icon' height={16}  width={16} src={isPasswordVisible ? Visible:Hide} />
					</button>
				)}
				</div>
		}
		return props.value ? props.value : "-";
	};

	return (
		<div style={{
			width: '100%',
		}}>
			<div style={{
				fontSize: 10,
				letterTransform: 'uppercase',
				marginTop: 20,
				color: "#bcbcbc"
			}}>
				{props.title}
			</div>
			<div style={{
				...props.style,
				marginTop: 8,
				border: '1px solid var(--border-color)',
				padding: '12px 20px',
				color: "var(--text-color)",
				borderRadius: 8,
				fontSize: '12px'
			}}>
				{displayValue()}
			</div>
		</div>
	)

}


FunctionalKeyValue.propTypes = {
	title: Props.string.isRequired,
	value: Props.string
}

export {
	FunctionalKeyValue
}
