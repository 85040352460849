import React, { useEffect, useState } from 'react';
import { Modal } from "../../../../Common/fModal/Modal"
import SelectGST from '../../../../Common/fUtils/SelectGST';

export default ({onEdit,onClose,edit }) => {
    const [gst, setGst] = useState({
        value: 0,
        label: 0,
    });

   useEffect(() => {
        if(edit){
            setGst({
                value: edit.taxSlab,
                label: edit.taxSlab,
            })
        }
    },[edit])

    return (
        <Modal
            show={true}
            des="Edit line item"
            title={"Edit Line Item"}
            style={{
                save: {
                    background: "var(--primary-color)",
                    border: "2px solid var(--primary-color)",
                    padding: "8px 15px",
                    width: "100%",
                    color: "white",
                    curor: "pointer",
                    textAlign: "center",
                },
            }}
            buttonName="Update"
            close={onClose}
            onSave={() => {
                 onEdit(gst.value)
                 onClose()
            }}
        >
			<SelectGST defaultValue={gst} placeholder="Select Gst" onSelect={setGst}/>

        </Modal>
    )
}