import React, { useEffect, useRef, useState } from "react";
import { FunctionalInput } from "../../../../../Common/fInputs";
import { get } from "../../../../../../Model/Network/Config/Axios";
import { Modal } from "../../../../../Common/fModal/Modal";
import { showToast } from "../../../../../Common/fToast/ToastCallback";
import { SearchableSelect } from "../../../../../Common/Class/Select/SearchableSelec2";
import SelectGST from "../../../../../Common/fUtils/SelectGST";

export default ({ onClose ,onEdit,onSubmit,prefill}) => {
    const [state, setState] = useState({
        items: [],
        charges: prefill && prefill.charges,
        filtered: []
    })
    const [gst, setGst] = useState({
        value: 5,
        label: 5
    })
    const [tax, setTax] = useState(0)
    const { items, charges, filtered } = state
    const TITLE = useRef()
    const DESC = useRef()
    const TYPE = useRef()
    const AMOUNT = useRef()
    const TAX = useRef()
    const TOTAL = useRef()
    const APPLICABLE_ON = useRef()

    useEffect(() => {
        fetchList()
    }, [])

    const fetchList = () => {
        get(`vendor/additionalCharges/?offset=0`, (e, r) => {
            if (r) {
                setState({ ...state, items: r.additionalCharges.map((e) => ({...e,label:e.title})) })
            }
        })
    }

    useEffect(() => {
        if (prefill) {
            setState(prev => ({...prev,
             charges:prefill.charges
            }))
            setGst({
                label:prefill.taxSlab,
                value:prefill.taxSlab,
            })
            TITLE.current.value = prefill.title
            TYPE.current.value = prefill.type
            DESC.current.value = prefill.description
            AMOUNT.current.value = prefill.base
            APPLICABLE_ON.current.value = prefill.applicableOn
            TAX.current.value = prefill.taxAmount
            TOTAL.current.value = prefill.amount
        }

    }, [prefill])


    return (
        <Modal
            show={true}
            des="Add Additional Charges"
            title={"Add Additional Charges"}
            style={{
                save: {
                  background: "var(--primary-color)",
                  border: "2px solid var(--primary-color)",
                  padding: "8px 15px",
                  width: "100%",
                  textAlign: "center",
                },
              }}
            close={onClose}
            onSave={() => {
                let title = TITLE.current && TITLE.current.value
                let type = TYPE.current && TYPE.current.value
                let desc = DESC.current && DESC.current.value
                let base = AMOUNT.current && AMOUNT.current.value
                let applicableOn = APPLICABLE_ON.current && APPLICABLE_ON.current.value

                if (!charges) {
                    showToast({
                        type:'error',
                        message:"Please select additional charges"
                      })
                    return
                }

                if (!base) {
                    showToast({
                        type:'error',
                        message:"Please enter base amount"
                      })
                    return
                }
                let taxValue = tax.toFixed(2);
                let data = {
                    "id": charges.id,
                    "charges": charges,
                    "title": title,
                    "base": Number(base),
                    "description": desc,
                    "type": type || "",
                    "tax": Number(taxValue),
                    "taxSlab": Number(gst.value),
                    "amount": Number(taxValue) + Number(base)
                }
                if(applicableOn)  {
                    data.applicableOn = applicableOn;
                } else {
                    data.applicableOn = undefined;

                }
                prefill ? onEdit(data) :  onSubmit(data)
            }}>

            <SearchableSelect
                disabled={prefill}
                label="Select additional charges"
                placeholder="Select additional charges"
                onChange={val => {
                    if (val.length > 0) {
                        let allItems = items.filter(item => {
                            return JSON.stringify(item).toLowerCase().includes(val.toLowerCase());
                        })
                        setState({ ...state, filtered:allItems })
                    } 
                }}
                select={val => {
                    TITLE.current.value =val.title
                    TYPE.current.value = val.type
                    DESC.current.value = val.desc
                    APPLICABLE_ON.current.value = val.applicableOn
                    setState({ ...state, charges:val,filtered:[] })
                }}
                value={charges}
                items={filtered.length>0 ? filtered : items}
            />
               <FunctionalInput type="text" disabled title="Title" ref={TITLE}  />
               <FunctionalInput type="text" disabled title="Type" ref={TYPE}  />
               <FunctionalInput type="text"  title="Description" ref={DESC}  />
               <FunctionalInput type="text"  disabled title="Applicable on" ref={APPLICABLE_ON}  />
               <FunctionalInput type="number"  title="Base Amount" ref={AMOUNT} 
                 onChange={(e) => {
                    if (e) {
                        let val = e.target.value
                        const gstValue = gst.value;
                        const baseAmount = Number(val);
                        const tax = (baseAmount / 100) * Number(gstValue)
                        setTax(tax)
                        TOTAL.current.value = (tax + baseAmount).toFixed(2)
                        TAX.current.value = (tax).toFixed(2)
                    }
                }}
                 />
            <SelectGST defaultValue={gst} placeholder="Select Gst" onSelect={(val) => {
                const baseValue = Number(AMOUNT.current.value);
                const gstPercentage = Number(val.value);
                setGst({
                    value: gstPercentage,
                    label: val.label
                })
                const tax = (Number(baseValue) * gstPercentage / 100)
                setTax(tax)
                TAX.current.value = (tax).toFixed(2)
                TOTAL.current.value = (tax + baseValue).toFixed(2)
            }} />
              <FunctionalInput title="Tax Amount" disabled placeholder="Tax amount" ref={TAX} />
               <FunctionalInput title="Total Amount" disabled placeholder="amount" ref={TOTAL} />
        </Modal>
    )
}