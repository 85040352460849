import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import './MultiMaster.css'
import { get, post } from '../../../../Model/Network/Config/Axios';




const MultiMaster = ({handleRef,placeholder,details,master,label,morph,onUpdate,defaultValue}) => {

  const [suggestion, setSuggestion] = useState([]);
  const [next, setNext] = useState(undefined);
  const [multiSelected,setMultiSelected] =useState([]);

  const getValue = useCallback(() => {
    let value = '';
    multiSelected.forEach((item, index) => {
      if (index === multiSelected.length - 1) {
        value += item.value;
      } else {
        value += item.value + '::';
      }
    });

    return value;
  }, [multiSelected]);

  useEffect(()=>{
    if(defaultValue){
        defaultValue = defaultValue.split('::').map(el => ({label:el,value:el}));
        setMultiSelected(defaultValue)
    }
  },[defaultValue])


  useEffect(() => {
    if (handleRef) {
        handleRef({getValue});
    }
  }, [getValue]);

  const onChange = (e) => {
    search(e);
  };

  const search = (q) => {
    if (q.length > 0) {
      get(`/forms/${details.id}/master`, (e, r) => {
        if (r) {
          let data = {};
          let next = r.next;
          if (r.data.length > 0) {
            r.data.forEach((val) => {
              data[val] = master[val];
            });

            post(`/forms/${details.id}/search`, { q, data }, (e, r) => {
              if (r) {
                setSuggestion(r.results);
                setNext(next);
              }
            });
          } else {
            post(`/forms/${details.id}/search`, { q }, (e, r) => {
              if (r) {
                setSuggestion(r.results);
                setNext(next);
              }
            });
          }
        }
      }, { q });
    } else {
      setSuggestion([]);
    }
  };


  return (
    <>
        <div className='text-start mb1 mt2'>
			{(label&&!morph)&&<label  className={`text-input-label `}>{label}</label>}
		</div>
        <Select
            isMulti={true}
            placeholder={placeholder}
            value={multiSelected.map(item=>({label:item.value,value:item.value}))}
            options={suggestion.map(item=>{
                let items = Object.keys(item).map(i => i);
                let label = ``;
                items.forEach(i=>{
                    label += `${i} : ${item[i]} ,`
                })
                return {
                    label,
                    value : item[next]
                }
            })}
            onChange={(selected)=>{
                if(selected.length>0) {
                    onUpdate(selected, next);
                }
                setMultiSelected(selected);
            }}
            onInputChange={onChange}
            />
    </>
)}


export default MultiMaster;