import React, {useEffect, useRef, useState} from 'react'
import {ShownSelect2} from "../Class/Select/index4";
import PropTypes from 'prop-types'
import {v4} from 'uuid'
import DownArrow from './down-arrow.png'
import {Modal} from "../fModal/Modal";
import { get } from '../../../Model/Network/Config/Axios';
import CustomFieldSearch from '../CustomFieldSearch';
import { showToast } from '../fToast/ToastCallback';

function Filters(props) {
	let [suggestions,setSuggestions] = useState([])
	let [customField,setCustomField] = useState(false)
	const [allFieldMaster, setAllFieldMaster] = useState([]);
	let FILTER = React.createRef();
	let CUSTOMFIELDFILTER = useRef();

	const getAllFieldMaster = () => {
		get(`workspace/customFields`, (e, r) => {
			if (r) {
				r.fieldList = r.fieldList.map(el => ({ label: el.field, value: el.field }));
				setAllFieldMaster(r.fieldList)
			}
		})
	}

	useEffect(() => {
		getAllFieldMaster();
	}, [])

	let addFilter = () => {
		let value = FILTER.current.value;
		if (value) {
			FILTER.current.value = "";
			props.addFilter(value)
		}
	}
	let handleEnter = (e) => {
		let value = FILTER.current.value;
		if (e.key === "Enter") {
			if (value) {
				FILTER.current.value = "";
				props.addFilter(value)
			}
		}
	}
	let onChange = e => {
		let value = FILTER.current.value;
		if (value) {
			let key = props.type.autoPopulate ? props.type.autoPopulate : props.type.value;
			let suggestions = props.suggestion ? props.suggestion[key] ? props.suggestion[key] : [] : [];
			suggestions = suggestions.filter(item => {
				if (item.firstCriteria.toLowerCase().includes(value.toLowerCase()))
					return true;
				if (item.secondCriteria && item.secondCriteria.toLowerCase().includes(value.toLowerCase()))
					return true;
				if (item.thirdCriteria && item.thirdCriteria.toLowerCase().includes(value.toLowerCase()))
					return true;
				if (item.value.toLowerCase().includes(value.toLowerCase()))
					return true;
				return false;
			})
			setSuggestions(suggestions)
		}
	}
	let suggestionItem = item => {
		return (
			<div key={v4()}>
				<p onClick={() => {
					FILTER.current.value = props.type.autoValue ? item[props.type.autoValue] : item.value;
					props.addFilter(FILTER.current.value);
					FILTER.current.value = "";
					setSuggestions( [])
				}} className="hover-background" style={{
					padding: '10px 15px',
					borderBottom: '1px solid var(--border-color)',
					fontSize: 15,
					cursor: 'pointer',
					fontWeight: 400
				}}>
					{props.type.autoValue ? item[props.type.autoValue] : item.value}
				</p>
			</div>
		)
	}

	return (
		<div style={props.containerStyle1}>
			<div style={{
				marginTop: 12,
				marginBottom: 10,
				display: 'flex',
				flexDirection: 'row', ...props.containerStyle
			}}>
				<div style={{ width: 135, position: 'relative' }}>
					<ShownSelect2 inputStyle={{ textAlign: 'left', zIndex: 1 }} placeholder="Select Filter Type"
								  items={props.types} select={type => {
						props.onTypeChange(type)
					}} value={props.type} />
					<img src={DownArrow} alt="down-arrow" style={{
						width: 14, height: 14,
						position: 'absolute', right: 12,
						top: 12,
						zIndex: 0
					}} />
				</div>
				<div style={{ width: 250, position: 'relative' }}>
					<input onKeyPress={handleEnter} onChange={onChange}
						   ref={FILTER} style={{
						border: '1px solid var(--border-color)',
						height: 45,
						width: 250,
						color: "var(--text-color)",
						borderLeft: 'none',
						fontSize: 13,
						padding: '9px 10px'
					}} type="text" placeholder={`Filter ${props.type.label.toLowerCase()}`} />
					{suggestions.length > 0 ? <div style={{
						width: 250,
						border: '1px solid var(--border-color)',
						position: 'absolute',
						top: 45,
						right: -1,
						left: -1,
						zIndex: 11,
						maxHeight: 225,
						overflowY: 'auto',
						backgroundColor: 'var(--white-color)'
					}}>
						{
							suggestions.map(suggestionItem)
						}
					</div> : null}
				</div>
				<div style={{
					display: 'flex'
				}}>
					<p onClick={addFilter} style={{
						height: 44,
						fontSize: 13,
						padding: '12px 20px',
						marginLeft: 8,
						width: 100,
						marginTop: 1
					}} className="btn btn-portlet-invert">
						ADD
					</p>
					{props.customField ? <p onClick={() => {
						setCustomField(true)
					}} style={{
						height: 44,
						fontSize: 13,
						padding: '12px 20px',
						marginLeft: 8,
						width: 175,
						marginTop: 1
					}} className="btn btn-danger-invert">
						Add Custom Field
					</p> : null}
				</div>
			</div>
			<div style={{ marginBottom: 20, ...props.selectFilter }}>
				{
					props.filters.map(item => (
						<span key={`${item.type}::${item.value}`} className="btn btn-portlet-invert"
							  style={{ marginRight: 5, display: 'inline', height: 20, padding: '4px 10px' }}>
								<span onClick={()=>{
									props.removeFilter(item)
								}} style={{
									marginRight: 5,
									marginTop: 4,
									fontSize: 13,
									cursor: 'pointer'
								}}>×</span>
							{item.type}: {item.value}
							</span>
					))
				}
			</div>
			{
				customField ?
					<Modal des="Add filter for custom value." buttonName="Add Custom Filter" show={true} style={{
						save: {
							background: "#717dfb",
							border: "2px solid #717dfb",
							padding: '8px 15px',
							width: '100%',
							textAlign: 'center'
						}
					}} onSave={() => {
						if(!CUSTOMFIELDFILTER.current.GET()){
							showToast({message: 'Please select a value', type: 'error'});
							return;
						}
						props.addFilter(CUSTOMFIELDFILTER.current.GET(),true);
						setCustomField(false);
					}} title="Add Filter" close={() => {
						setCustomField(false)
					}}>
						<CustomFieldSearch masterList={allFieldMaster} ref={CUSTOMFIELDFILTER} formType='utilityLinking' />
					</Modal> : null
			}
		</div>
	)
}


Filters.propTypes = {
	types: PropTypes.array.isRequired,
	type: PropTypes.object,
	onTypeChange: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	removeFilter: PropTypes.func.isRequired,
	addFilter: PropTypes.func.isRequired,
	suggestion: PropTypes.object
}

export {
	Filters
}
