import React, {useEffect, useState} from 'react'
import {get} from "../../../../Model/Network/Config/Axios";
import {handleError} from "../../../Common/fToast/ToastCallback";
import {SidePane} from '../../../Common/fSidePane/RightPane'
import {Spinner} from "../../../Common/fLoader";
import Invoice from "./India";


function ViewCreditNote(props) {
	let [loading,setLoading] = useState(true)
	const [details, setDetails] = useState({});
	const [creditId,setCreditId] = useState(undefined);
	const [isIndependentCreditNote,setIsIndependentCreditNote] = useState(false);

	useEffect(() => {
		if(props.id) fetchInvoice(props.id)
	}, [props.id,props.creditId]);

	const fetchInvoice = (ID)=>{
		get(`vendor/vendor/creditNote/getDetails/${ID}`,(e,r)=>{
			if(r){
				setDetails(r);
				setIsIndependentCreditNote(!r.invoice)
				setCreditId(r.creditNoteId)
				setLoading(false);

			}else if(e){
				handleError(e)
			}
		})
	}

	if(loading){
		return  <SidePane column={<Spinner/>} button={false} onClose={() => props.onClose(false)}>
			<Spinner/>
		</SidePane>
	}

	return  (
		<Invoice  details={details} onReload={()=>{fetchInvoice(props.id)}} init={props.init} onClose={props.onClose} id={props.creditId?props.creditId:creditId} isIndependentCreditNote={isIndependentCreditNote}/>
	)	
	
}

export  {
	ViewCreditNote
}
