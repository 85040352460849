import React, {useState} from 'react'
import Props from 'prop-types'
import Empty from "../fCard/Empty";
import {Tiles} from "./Tiles";
import {get, set} from "../../../Utils/Crypto";
import {ButtonOutline} from "../fCard/Tile";
import Fade from 'react-reveal/Fade'
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';

function TableContainer(props) {

	let tile = get("tile-setting")
	if(!tile)
		tile = "card";
	if(!props.mapCard && !props.mapKanban){
		tile = "list";
	}
	if(tile==="kanban" && !props.mapKanban){
		tile = "list";
	}
	if(tile==="list" && !props.mapTile){
		tile = "card"
	}
	let [search, setSearch] = useState(props.search)
	if(search==='true'){
		setSearch(true);
	}
	let [page,setPage] = useState(0);
	let [type,setType] = useState(tile)
	let pagination = () => {
		let pagecount = props.pagecount ? props.pagecount : 15;
		let start = page * pagecount + 1;
		let end = page * pagecount + pagecount;
		let length = props.items.length
		if (end > length)
			end = length;
		if (start > length)
			start = length;
		if(props.disablePagination) return null;
		return (
			<div className="fintrip-table-footer">
				<img style={{ cursor: 'pointer' }} onClick={() => {
					if ((start + pagecount) <= (length)) {
						setPage(page+1)
					}
				}} alt="left arrow" className="right-arrow" src={require('./right-arrow (2).png')} />
				<img style={{ cursor: 'pointer' }} onClick={() => {
					if (page > 0) {
						setPage(page-1)
					}
				}} alt="right arrow" className="left-arrow" src={require('./left-arrow (1).png')} />
				<p>
					{start} - {end} of {length} results
				</p>
			</div>
		)
	}
	let data = props.items ? props.items : [];
	let pagecount = props.pagecount ? props.pagecount : 15;
	let start = page * pagecount;
	let end =page * pagecount + pagecount;
	data = data.slice(start, end)
	
	return (
		<div>
			<div className="title-container4">
				<div style={{
					padding: '30px 7px',
					paddingBottom: 10,
				}}>
					<div style={{display:'flex',position:'relative',minHeight:30, width: '100%'}}>
						<div style={{display:'flex', flexWrap: 'wrap',gap: '10px 0px', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
							<div style={{
								fontWeight: 'bold',
								fontSize: 13,
								display: 'flex',
								alignItems: 'center',
								flexGrow: 10,
								...props.Style
							}}>
								<div style={{marginRight: '20px'}}>{props.items.length} {props.title}</div>
								 <div style={{ marginTop:20}}> 
								 </div>
								{
									props.button?
										<ReadOnly>
											<ButtonOutline  adminRoles="hrms.write" title={props.button.title} onClick={() => {
											props.button.click()
											}}/>
										</ReadOnly>:undefined
								}
								{
									props.children
								}
							</div>
							<div style={{display:'flex', justifyContent: 'space-between',flexGrow: 1}}>
								<Tiles kanban={props.mapKanban} card={props.mapCard} tile={props.mapTile} type={type} setType={type=>{
									setType(type)
									set("tile-setting",type)
								}}/>
								{
									pagination()
								}
							</div>
						</div>
					</div>
					{search &&(
						<div style={{display:'flex', position:'relative', marginTop:'20px', marginBottom:'20px'}}>
							<div style={{flex:'1 1 0%', height:'40px', borderRadius:'1px'}}>
								<input style={{height:'100%', width:props.width, flex:'1 1 0%', border:'1px solid #ddd', backgroundColor:'rgb(255,255,255)', fontSize:'16px', borderRadius:'8px', padding:'16px'}} 
									type='text'
									placeholder={props.placeHolder}
									onChange={(e)=>{props.onChangeValue(e.target.value)}}
									/>
							</div>
						</div>
					)
					}

					{type==='list' && props.headers && props.headers.length?
					  <div style={{height:50,background:"#fff",display:'flex',boxShadow:'2px 2px 1px rgba(1,1,1,.1)',marginTop:20,border:'1px solid var(--border-color)',marginLeft:-10,marginRight:-10,borderRadius:4}}>
						  {props.headers.map((item,index)=>{
							  return(
								  <div key={index} style={{display:'flex',boxSizing:'content-box',fontSize:14,alignItems:'center',height:'inherit',...item.style}}>
									  {item.name}
								  </div>
							  )
						  })}
					  </div>
					  :null
					}
					<div>
						<div>
							<div style={{position:'relative',marginTop:18,marginLeft:-10,marginRight:-10}} className="">
								<div className="fintrip-office-container" style={props.bodyStyle}>

									{data.map((item, i) => {
										if(type==="list"){
											return (
												<div key={i} style={{width:'100%'}}><Fade>{props.mapTile(item, i)}</Fade></div>
											)
										}else if(type==="card"){
											return (
												<div key={i}><Fade>{props.mapCard(item, i)}</Fade></div>
											)
										}
										return (<></>)
									})}
									{data.length === 0 ? <Empty/>: null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


TableContainer.propTypes = {
	mapCard : Props.func,
	mapTile : Props.func,
	mapKanban : Props.object,
	title:Props.string.isRequired,
	items:Props.array.isRequired
}

export {
	TableContainer
}
