
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '../../../../../../Common/fModal/Modal';
import { SearchableSelect } from '../../../../../../Common/Class/Select/SearchableSelec';
import { bankingGetApis, get, post } from '../../../../../../../Model/Network/Config/Axios';
import { handleError, showToast } from '../../../../../../Common/fToast/ToastCallback';
import { FloatInput } from '../../../../../../Common/Class/FloatLabelInput';


export default ({onClose,reload,prefill=undefined,onVaAcc=undefined}) => {
    const [state,setState] = useState({
        loading:false,
        items:[],
        filtered:[],
        selected:undefined,
        disableAcc:false
    })
    
    const {selected}= state
    const REASON = useRef()
    const AMOUNT = useRef()

    useEffect(() =>{
        const fetchData = async () => {
           const accounts = await init();
            if (prefill && prefill.officeId) {
                const office = prefill.officeId;
                get(`heirarchy/offices/${office}/details`, (e, r) => {
                    if (r) {
                        const tags = r.tags || {};
                        const selected = accounts.find(account => account.name === tags['account.utility'])
                        if (selected) {
                            setState((prevState) => ({
                                ...prevState,
                                selected:{label:selected.name,value:selected.id}
                            }));
                        }
                    }
                });
              if(prefill && prefill.bill && prefill.bill.amount){
                AMOUNT.current.setValue(prefill.bill.amount)
              }
            }
            if(onVaAcc){
                const selected = accounts.find(account => account.name === onVaAcc)
                if (selected) {
                    setState((prevState) => ({
                        ...prevState,
                        disableAcc:true,
                        selected:{label:selected.name,value:selected.id}
                    }));
                }
            }
        };
    
        fetchData();
    },[prefill])
  
    const init = () => {
        return new Promise((resolve, reject) => {
            bankingGetApis('payments/activeVA', (e, r) => {
                if (r) {
                    const accounts = r.accounts.filter((account) => (prefill && prefill.officeId) ? account.defaultUtility : true)
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                        items: r.accounts.filter((account) => (prefill && prefill.officeId) ? account.defaultUtility : true),
                        filtered: r.accounts.filter((account) => (prefill && prefill.officeId) ? account.defaultUtility : true),
                    }));
                    resolve(accounts);
                } else if (e) {
                    handleError(e);
                    resolve([]);
                }
            });
        });
    };



    const onSelect = (value) => {
        setState({...state, selected: value })
    }

    const onChange = (value) => {
        setState({...state, 
            items: state.filtered
            .filter(e => JSON.stringify(e).toLocaleLowerCase().includes(value.toLowerCase()))
         })
    }

    return (
        <Modal
            buttonName="Submit"
            show={true}
            stateLoading={state.loading}
            onSave={() => {
                const reason = REASON.current.getValue();
                const amount = AMOUNT.current.getValue();
                if (!selected || (selected && !selected.value)) {
                    showToast({
                        type: 'error',
                        message: "Please select an account"
                    })
                    return
                }
                if (!reason) {
                    showToast({
                        type: 'error',
                        message: "Please enter the purpose"
                    })
                    return
                }
                if (!amount) {
                    showToast({
                        type: 'error',
                        message: "Please enter the amount"
                    })
                    return
                }

                setState({ ...state, loading: true })
                let data = {
                    "purpose": reason,
                    "amount": Number(amount)
                }
                if(prefill && prefill.attrs && prefill.attrs.invoice_id){
                    data.reference1 =  prefill.attrs.invoice_id
                }
                post(`transfers/rechargeRequest/${selected.value}/request.recharge`,data, (e, r) => {
                    if (r) {
                        showToast({
                            type: 'success',
                            message: "Wallet request send successfully"
                        })
                        reload()
                        onClose()
                    } else {
                        handleError(e)
                    }
                    setState({ ...state, loading: false })
                })
            }}
            close={onClose}
            title="Recharge VA Wallet"
            des="Please Enter the details to recharge your VA wallet"
            style={{
                save: {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 17,
                    background: "var(--primary-color)",
                    border: "2px solid var(--primary-color)",
                    marginTop: 20,
                    padding: "12px 20px"
                },
            }}
        >
            <SearchableSelect
                items={state.items.map((account) => ({ label: account.name, value: account.id }))}
                onChange={onChange}
                disabled={state.disableAcc}
                label="Active  Account"
                placeholder={"Select account"}
                select={onSelect}
                value={state.selected}
            />
            <FloatInput
                title="Enter Purpose"
                type="text"
                handleRef={ref => REASON.current = ref}
            />
            <FloatInput
                title="Enter Amount"
                type="number"
                disabled={prefill && prefill.bill && prefill.bill.amount}
                handleRef={ref => AMOUNT.current = ref}
            />
       </Modal>
    )
}