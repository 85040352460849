import React, {useEffect, useState} from 'react'
import {get} from "../../../../Model/Network/Config/Axios";
import {handleError} from "../../../Common/fToast/ToastCallback";
import {SidePane} from '../../../Common/fSidePane/RightPane'
import {Spinner} from "../../../Common/fLoader";
import Invoice from "./India";
import Others from "./Others";


function DebitNoteInvoice(props) {
	
	let [loading,setLoading] = useState(true)
	const [details, setDetails] = useState({});
	const [debitNote,setDebitNote] = useState(undefined);
	const [debitNoteDetails,setDebitNoteDetails] = useState(undefined);
	const [invoiceId,setInvoiceId] = useState(undefined);

	useEffect(() => {
		fetchInvoice(props.invoiceNumberId ? props.invoiceNumberId : props.id)
	}, [props.id,props.invoiceId,props.invoiceNumberId]);

	const fetchInvoice = (ID)=>{
		get(`vendor/invoicing/${ID}/details`,(e,r)=>{
			if(r){
				setDetails(r);
				setInvoiceId(r.invoice.id)
				setLoading(false);
				 if(r.debitNote) {
					get(`vendor/invoicing/${props.invoiceId}/debitNote`,(e,res)=>{
						if(res) {
							setDebitNote(true);
							setDebitNoteDetails(res);
							setLoading(false)
						}
					})
				 } else {
					setDebitNote(false);
					setLoading(false)
				 }
			}else if(e){
				handleError(e)
			}
		})
	}

	if(loading){
		return  <SidePane column={<Spinner/>} button={false} onClose={() => props.onClose(false)}>
			<Spinner/>
		</SidePane>
	}

	if(details.renderer==="INDIA"){
		return  (
			<Invoice init={props.init} debitNote={debitNote} debitNoteDetails={debitNoteDetails} details={details} onReload={()=>{fetchInvoice(props.id)}} onClose={props.onClose} id={props.invoiceId?props.invoiceId:invoiceId}/>
		)
	}else {
		return  (
			<Others details={details} onReload={()=>{fetchInvoice(props.id)}} onClose={props.onClose} id={props.invoiceId?props.invoiceId:invoiceId}/>
		)
	}
}

export  {
	DebitNoteInvoice
}
