import React from 'react'
import Props from 'prop-types'
import Validator from 'validator'
import Moment from 'moment'
import {DatePicker} from "@material-ui/pickers";
import checkIcon from "./icons8-checked-checkbox-80.png"

class FloatInput extends React.Component {

	state = {
		label: '',
		className: ""
	}


	onBlur = () => {
		if (this.props.onBlur)
			this.props.onBlur()
		let className = "";
		let required = this.props.required;
		let value = this.mRef.value;
		switch (this.props.type) {
			case "email":
				if (!value) {
					if (required)
						className = "invalid";
					break
				}
				if (!Validator.isEmail(value)) {
					className = "invalid";
					break
				} else {
					className = "valid";
					break
				}
			case "password": {
				if (value && value.length > 4) {
					className = "valid";
				} else {
					className = "invalid";
				}
				break
			}
			case "text":
			default:
				if (required) {
					if (value)
						if (this.props.customValidator) {
							if (this.props.customValidator(value))
								className = "valid";
							else
								className = "invalid"
						} else {
							className = "valid";
						}
					else
						className = "invalid";
				} else {
					if (value) {
						className = "valid";
					}
				}
				break
		}
		this.setState({
			...this.state,
			className
		})
	}

	updator = val => {
		this.mRef.value = val;
		this.setState({
			...this.state,
			className: ""
		})
	}

	componentDidMount() {
		if (this.props.defaultValue) {
			this.mRef.value = this.props.defaultValue;
		}
	}

	getValue = () => {
		return this.mRef.value;
	}

	 blueOnScroll = (e) => {
		if(document.activeElement.type === "number"){
			document.activeElement.blur();
		}
	  };

	render() {

		return (
			<div className="full-width" style={this.props.style}>
				{
					this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label> : null
				}
				<div style={this.props.style} className={`floating-label ${this.state.className}`} onClick={()=>{
					if(this.props.onClick) {
						this.props.onClick()
					}
				}}>
					<input 
					defaultValue={this.props.defaultValue}
					disabled={this.props.disabled}
					 {...this.props.inputProps}
					  style={this.props.inputStyle} 
					  onWheel={this.blueOnScroll}
					 onBlur={() => {
						if (this.props.onEdited) {
							this.props.onEdited()
						}
					this.onBlur()
					}} ref={ref => {
						this.mRef = ref;
						if (this.props.handleRef) {
							this.props.handleRef({
								ref,
								setValue: this.updator,
								float: true,
								value: () => ref.value,
								getValue: ()=>ref.value
							})
						}
					}} min={this.props.diasblePrev? new Date().toISOString().split('T')[0] :this.props.min} autoComplete='off' type={this.props.type} placeholder={this.props.placeholder ?
						this.props.placeholder : this.props.title} onChange={e => {
							this.onBlur()
							if (this.props.onChange) {
								this.props.onChange(e)
							}
						}} maxLength={this.props.maxLength} />
				</div>
				{
					this.props.blue ?
						<p style={{
							fontSize: 9,
							color: "#EE3F5a",
							margin: 0
						}}>{this.props.blue}</p> : null
				}
			</div>
		)
	}
}

FloatInput.propTypes = {
	title: Props.string,
	type: Props.string.isRequired,
	handleRef: Props.func,
	customValidator: Props.func
}

FloatInput.defaultProps = {
	required: true,
	inputProps: {},
	min: 1,
	maxLength: 2000
}

class DeltaFloatInput extends React.Component {

	state = {
		label: '',
		className: "",
		delta: undefined
	}


	onBlur = () => {
		if (this.props.onBlur)
			this.props.onBlur()
		let className = "";
		let required = this.props.required;
		let value = this.mRef.value;
		switch (this.props.type) {
			case "email":
				if (!value) {
					if (required)
						className = "invalid";
					break
				}
				if (!Validator.isEmail(value)) {
					className = "invalid";
					break
				} else {
					className = "valid";
					break
				}
			case "password": {
				if (value && value.length > 4) {
					className = "valid";
				} else {
					className = "invalid";
				}
				break
			}
			case "text":
			default:
				if (required) {
					if (value)
						if (this.props.customValidator) {
							if (this.props.customValidator(value))
								className = "valid";
							else
								className = "invalid"
						} else {
							className = "valid";
						}
					else
						className = "invalid";
				} else {
					if (value) {
						className = "valid";
					}
				}
				this.setState((prevValue)=>{
					return{
						...prevValue,
						delta: value
					}
				})
				break
		}
		this.setState((prevValue)=>{
			return{
				...prevValue,
				className
			}
		})
	}

	updator = val => {
		this.mRef.value = val;
		this.setState((prevValue)=>{
			return{
				...prevValue,
				className: ""
			}
		})
	}

	componentDidMount() {
		if (this.props.defaultValue) {
			this.mRef.value = this.props.defaultValue;
		}
	}

	getValue = () => {
		return this.mRef.value;
	}

	 blueOnScroll = (e) => {
		if(document.activeElement.type === "number"){
			document.activeElement.blur();
		}
	  };

	render() {

		return (
			<div className="full-width" style={this.props.style}>
				{
					this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label> : null
				}
				<div style={{...this.props.style,position:'relative'}} className={`floating-label ${this.state.className}`} onClick={()=>{
					if(this.props.onClick) {
						this.props.onClick()
					}
				}}>
					<input 
					disabled={this.props.disabled}
					 {...this.props.inputProps}
					  style={this.props.inputStyle} 
					  onWheel={this.blueOnScroll}
					 onBlur={() => {
						if (this.props.onEdited) {
							this.props.onEdited()
						}
					this.onBlur()
					}} ref={ref => {
						this.mRef = ref;
						if (this.props.handleRef) {
							this.props.handleRef({
								ref,
								setValue: this.updator,
								float: true,
								value: () => ref.value,
								getValue: ()=>ref.value
							})
						}
					}} min={this.props.diasblePrev? new Date().toISOString().split('T')[0] :this.props.min} autoComplete='off' type={this.props.type} placeholder={this.props.placeholder ?
						this.props.placeholder : this.props.title} onChange={e => {
							this.onBlur()
							if (this.props.onChange) {
								this.props.onChange(e)
							}
						}} maxLength={this.props.maxLength} />
						{((this.props.type === "text" || "date") && (this.state.delta || this.state.delta === ""))? 
						<div style={{position:"absolute",right:0,cursor:'pointer'}}><img alt='check icon' height={48} src={checkIcon} 
						onClick={()=>{
							this.props.update(this.state.delta);
							this.setState((prevValue)=>{
								return {
									...prevValue,
									delta: undefined
								}
							})
						}}/></div>
						:null}
				</div>
				{
					this.props.blue ?
						<p style={{
							fontSize: 9,
							color: "#EE3F5a",
							margin: 0
						}}>{this.props.blue}</p> : null
				}
			</div>
		)
	}
}

DeltaFloatInput.propTypes = {
	title: Props.string,
	type: Props.string.isRequired,
	handleRef: Props.func,
	customValidator: Props.func
}

DeltaFloatInput.defaultProps = {
	required: true,
	inputProps: {},
	min: 1,
	maxLength: 2000
}



class FloatDate extends React.Component {


	state = {
		date: Moment()
	}

	value = () => {
		return this.state.date.format("DD-MM-YYYY");
	}

	getValue = () => {
		return Moment(this.state.date).valueOf();
	}


	componentDidMount() {
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	render() {

		return (
			<div className="full-width" style={this.props.style}>
				{
					this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label> : null
				}
				<div style={this.props.style} className={`floating-label`}>
					<DatePicker TextFieldComponent={text => {
						return (
							<p onClick={text.onClick} style={{
								padding: 12
							}}>
								{text.value}
							</p>
						)
					}} value={this.state.date} onChange={date => {
						this.setState({
							date
						})
					}} />
				</div>
			</div>
		)
	}
}

FloatDate.propTypes = {
	title: Props.string,
	handleRef: Props.func,
}






class FloatDateTime extends React.Component {


	state = {
		date: Moment()
	}

	value = () => {
		return this.state.date.format("DD-MM-YYYY");
	}

	getValue = () => {
		return Moment(this.state.date).valueOf();
	}


	componentDidMount() {
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	render() {

		return (
			<div className="full-width" style={this.props.style}>
				{
					this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label> : null
				}
				<div style={this.props.style} className={`floating-label`}>
					<DatePicker TextFieldComponent={text => {
						return (
							<p onClick={text.onClick} style={{
								padding: 12
							}}>
								{text.value}
							</p>
						)
					}} value={this.state.date} onChange={date => {
						this.setState({
							date
						})
					}} />
				</div>
			</div>
		)
	}
}

FloatDateTime.propTypes = {
	title: Props.string,
	handleRef: Props.func,
}


class FloatArea extends React.Component {

	state = {
		label: '',
		className: ""
	}




	updator = val => {
		this.mRef.value = val;
		this.setState({
			...this.state,
			className: ""
		})
	}

	getValue = () => {
		return this.mRef.value;
	}


	render() {

		return (
			<div className="full-width" style={this.props.style}>
				{
					this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label> : null
				}
				<div style={{
					...this.props.style,
					height: 116
				}} className={`floating-label ${this.state.className}`}>
					<textarea  {...this.props.inputProps} style={{
						...this.props.inputStyle,
						height: 240,
						border: 'none',
						padding: 8,
						resize: 'none',
						fontSize: 15,
						color: "inherit"
					}} ref={ref => {
						this.mRef = ref;
						if (this.props.handleRef) {
							this.props.handleRef({
								ref,
								setValue: this.updator,
								float: true,
								value: () => ref.value
							})
						}
					}} placeholder={this.props.title} onChange={e => {
						if (this.props.onChange) {
							this.props.onChange(e)
						}
					}} />
				</div>
			</div>
		)
	}
}

FloatArea.propTypes = {
	title: Props.string,
	type: Props.string.isRequired,
	handleRef: Props.func.isRequired,
	customValidator: Props.func
}

FloatArea.defaultProps = {
	required: true,
	inputProps: {}
}

class FloatSelect extends React.Component {

	state = {
		label: '',
		className: ""
	}

	componentDidMount() {
		if(this.props.value) {
			this.updator(this.props.value.value);
		}
	}

	componentDidUpdate(props,prevProps) {
		if(props.value!==prevProps.value) {
		}
	}

	updator = val => {
		this.mRef.value = val;
		this.setState({
			...this.state,
			className: ""
		})
	}

	getValue = () => {
		if(this.mRef.value==='Select one...') {
			return undefined
		}
		return this.mRef.value;

	}



	value = () => {
		return this.mRef.value;
	}


	render() {

		
		return (
			<div className="full-width" style={this.props.style}>
				{
					this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
						{
							this.props.title
						}:
					</label> : null
				}
				<div className={`floating-label`} style={{ ...this.props.labelInput }}>
					<select 
					defaultValue={this.props.defaultValue}
					onChange={e => {
						if (this.props.onChange) {
							this.props.onChange(this.value())
						}
					}} style={{
						flex: 1,
						border: 'none',
						textAlign: 'left',
						fontWeight: 400,
						fontSize: 15,
						color: 'var(--text-color)',
						paddingLeft: 10
					}} ref={ref => {
						this.mRef = ref;
						if (this.props.handleRef) {
							this.props.handleRef({
								ref,
								setValue: this.updator,
								value: () => ref.value,
								getValue: this.getValue,
							})
						}
					}} disabled={this.props.disabled} placeholder={this.props.title}>
						{
							this.props.firstValue ?
							<option hidden selected disabled={true}>Select one...</option>
							:null
						}
						{
							this.props.items.map(item => (
								<option selected={this.props.defaultValue === item.key} value={item.key} key={item.key}>{item.value}</option>))
						}
					</select>
				</div>
			</div>
		)
	}
}

FloatSelect.propTypes = {
	title: Props.string,
	handleRef: Props.func,
	items: Props.array.isRequired
}

FloatSelect.defaultProps = {
	disabled:false,
	firstValue:false
}

export {
	FloatInput, FloatSelect, FloatArea, FloatDate,FloatDateTime,DeltaFloatInput
}
