import React from 'react';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';

class Index extends React.Component {

    getTaxBreakdown = (tax) => {
        const billTo = this.props.data.billingTo;
        const vendorGstin = this.props.data.vendor.gstin;

        let sgst = 0, cgst = 0, igst = 0;

        if (vendorGstin && vendorGstin.length > 2 && billTo && billTo.gstin && billTo.gstin.length > 2) {
            if (vendorGstin.slice(0, 2) === billTo.gstin.slice(0, 2)) {
                cgst = tax / 2;
                sgst = tax / 2;
            } else {
                igst = tax;
            }
        } else {
            cgst = tax / 2;
            sgst = tax / 2;
        }

        return { sgst, cgst, igst };
    };

    header = () => {
        return (
            <div style={{
                display: 'flex',
                height: 45,
                flex: 1,
                borderBottom: '1px solid var(--border-color)',
                fontWeight: 700
            }}>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>S. NO.</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>DESCRIPTION</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>HSN/SAC <br />CODE</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>QTY</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>Unit</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>Rate</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>TOTAL</div>
            </div>
        )
    };

    mapItem = (item, index) => {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                height: 50
            }} key={index}>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{index + 1}</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{item.name}</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{item.hsn}</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{item.qtty}</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{item.unit}</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{numberWithCommas(item.price.toFixed(2))}</div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{this.props.currency} {numberWithCommas(item.base.toFixed(2))}</div>
            </div>
        )
    };
    render() {
        console.log("inside this component");
        const { items } = this.props;
        let tax = 0;
        let totalAmount = 0;
    
        items.forEach(val => {
            tax += val.tax;
            totalAmount += val.amount;
        });
    
        const { sgst, cgst, igst } = this.getTaxBreakdown(tax);
    
        return (
            <div>
                {this.header()}
                {items.map(this.mapItem)}

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    paddingRight: 40,
                    marginTop: 20
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '300px',
                        color: 'gray',
                        marginBottom: 10
                    }}>
                        <div style={{ flex: 1, textAlign: 'right' }}>SGST:</div>
                        <div style={{
                            flex: 1,
                            textAlign: 'right',
                            fontWeight: 600,
                            color: 'black'
                        }}>
                            {this.props.currency} {numberWithCommas(sgst.toFixed(2))}
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '300px',
                        color: 'gray',
                        marginBottom: 10
                    }}>
                        <div style={{ flex: 1, textAlign: 'right' }}>CGST:</div>
                        <div style={{
                            flex: 1,
                            textAlign: 'right',
                            fontWeight: 600,
                            color: 'black'
                        }}>
                            {this.props.currency} {numberWithCommas(cgst.toFixed(2))}
                        </div>
                    </div>
    
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '300px',
                        color: 'gray',
                        marginBottom: 10
                    }}>
                        <div style={{ flex: 1, textAlign: 'right' }}>IGST:</div>
                        <div style={{
                            flex: 1,
                            textAlign: 'right',
                            fontWeight: 600,
                            color: 'black'
                        }}>
                            {this.props.currency} {numberWithCommas(igst.toFixed(2))}
                        </div>
                    </div>
    
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '300px',
                        color: 'gray',
                        marginBottom: 10
                    }}>
                        <div style={{ flex: 1, textAlign: 'right' }}>Total Tax:</div>
                        <div style={{
                            flex: 1,
                            textAlign: 'right',
                            fontWeight: 600,
                            color: 'black'
                        }}>
                            {this.props.currency} {numberWithCommas(tax.toFixed(2))}
                        </div>
                    </div>
    
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '300px',
                        color: 'black',
                        fontWeight: 700,
                        fontSize: '16px',
                        borderTop: '2px solid var(--border-color)',
                        paddingTop: 10
                    }}>
                        <div style={{ flex: 1, textAlign: 'right' }}>Total Amount:</div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            {this.props.currency} {numberWithCommas(totalAmount.toFixed(2))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
}

export default Index;