export const  GSTS = [
    {
        value: 0,
        label: 0,
      },
      {
        value: 3,
        label: 3,
      },
      {
        value: 5,
        label: 5,
      },
      {
        value: 12,
        label: 12,
      },
      {
        value: 18,
        label: 18,
      },
      {
        value: 28,
        label: 28,
      },
]