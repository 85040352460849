import React, { useState, useImperativeHandle, useRef } from "react";
import { get } from "../../../../../Model/Network/Config/Axios";
import { useSelector } from "react-redux";

const ProductSearch = React.forwardRef(({ placeholder,categoryId,onChangeText,disabled,services='',onSelect }, ref) => {
  const [productName, setProductName] = useState();
  const [suggestion, setSuggestion] = useState([]);
  let { productCategoryFilter } = useSelector(state => ({ ...state.auth.config.plugins }))

  let VALUE = useRef();

  const getUrl = () => {
    const categoryQueryParam = categoryId ? `category=${categoryId}` : '';
    if(productCategoryFilter) {
        return `vendor/product/search.buy?${categoryQueryParam}`;
    } else return `vendor/product/search.buy`
    }

  const onChange = (e) => {
    const value = e.target.value;
    setProductName({title:value});
    onChangeText && onChangeText({title:value});
    if (value) {
      get(getUrl(), (e, r) => {
          if (r) {
            setSuggestion(r.products.map((item) => ({ title: item.title, id: item.id, glCode: (item && item.gl)?item.gl:"",hsn:item.hsn, amount: item.amount })));
          }
        },
        {
          q: value,
          ...services ? {service:services}:{}
        }
      );
    } else {
      setSuggestion([]);
    }
  };
  
  const suggestionList = (item, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          setSuggestion([]);
          setProductName(item);
          onChangeText && onChangeText(item);
          onSelect && onSelect(item);
          if (ref && ref.current) {
            ref.current.value = item.title;
          }
          VALUE.current.value = item.title;
        }}
        className="flex flex-v-center hover"
        style={{
          height: 45,
          paddingLeft: 20,
          cursor: "pointer",
          color: "var(--input-color)",
        }}
      >
        <div>
          <p  style={{ fontSize: 12 }}>{item.title}</p>
        </div>
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return productName;
    },
    setValue: (val) => {
      setProductName(val)
      VALUE.current.value  = val.title
    },
  }));

  return (
    <div style={{ position: "relative" }}>
      <p className="input-label" >{placeholder}</p>
      <div
        className="flex flex-h-center flex-v-center"
        style={{
          borderRadius: 8,
          height: 50,
          marginTop: 10,
          cursor: "pointer",
          border: "1px solid var(--border-color)",
          marginBottom: 20,
        }}
      >
        <input
          disabled={disabled}
          ref={VALUE}
          style={{
            width: "98%",
            height: "90%",
            backgroundColor: "#fff",
            color: "var(--input-color)",
            fontSize: 16,
            padding: "10px 12px",
            border: "none",
          }}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
      {suggestion.length > 0 && (
        <div
          style={{
            position: "absolute",
            height: 210,
            overflowY: "auto",
            width: "100%",
            top: 70,
            paddingTop: 10,
            paddingBottom: 10,
            boxShadow: "0px 0px 5px 1px var(--input-backgroundColor)",
            zIndex: 10,
            border: "1px solid var(--border-color)",
            backgroundColor: "#fff",
          }}
        >
          {suggestion.map(suggestionList)}
        </div>
      )}
    </div>
  );
});

export default ProductSearch;
