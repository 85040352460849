import React, { useEffect, useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/fInputs";
import { Modal } from "../../../../Common/fModal/Modal";
import { Select } from "../../../../Common/Class/Select";
// import { ProductSearch } from "../../../fVendor/fProcurement/fRfqDetails/ProductSearch";
import { get } from "../../../../../Model/Network/Config/Axios";
import ProductSearch from "../../Submit/ProductSearch";
import _ from 'lodash';
import ToggleSwitch from "../../../../Common/fSwitch";
import TdsLineItem from "./TdsLineItem";
import SelectGST from "../../../../Common/fUtils/SelectGST";

const LineItemModal = ({ close ,edit,onSave,categoryId,category,vendorId,tdsAtLineItem=false,contractId,tdsAtLineItemInContract=false,disableUpdate,isCreditNote,searchProductByService=false}) => {

  let categoryList = ["BCCD CRM","BCCD Non - CRM"]
  const QTY = useRef(),
    MATERIAL = useRef(),
    UNIT = useRef(),
    PRICE = useRef(),
    NAME=useRef(),
    GLCODE=useRef();

  const [gst, setGst] = useState({
    value: 2,
    label: 2,
  });
  
  const [, setProduct] = useState(undefined);
	const [updated,setUpdated] = useState({
		content:{}
	});
  const [singleService, setSingleService] = useState({});
	const [services, setServices] = useState([]);
	const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (edit) {
      setProduct({ hsn: edit.hsn, name: edit.desc, label: edit.desc })
      QTY.current.value = edit.qtty || 0
      PRICE.current.value = edit.price
      MATERIAL.current.value = edit.hsn || ''
      UNIT.current.value = edit.unit || ''
      setGst({
        value: edit.taxSlab,
        label: edit.taxSlab
      })
      if (edit.service) {
        setSingleService({
          label: edit.service,
          value: null,
        })
        NAME.current.setValue({title:edit.desc,id:edit.productId})
      }
      if(edit && edit.glCode){
        GLCODE.current.value = edit.glCode || ''
      }
      setToggle(edit.rcItem)
    }
  },[edit])
  
  useEffect(() => {
    if(categoryId){
		get(`vendor/config/${categoryId}/services`, (e, r) => {
			if (r) {
				setServices(r.items.map(i => {
					return {
						value: i.id,
						label: i.name,
						cess:i.cess
					}
				}))
				// if (r.items.length > 0) {
				// 	setSingleService({
				// 		label: r.items[0].name,
				// 		value: r.items[0].id,
				// 		cess:r.cess
				// 	})
				// }

			}
		})
  } 
	}, [categoryId])

  // const selectProduct = (product) =>{
  //   MATERIAL.current.value =  product.hsn || '';
  //   UNIT.current.value =  product.unit
  //   setGst({
  //       value:product.gst,
  //       label:product.gst,
  //   })
  //   setProduct(product)
  //   updateFunction("productId",Number(product.value))
  //   updateFunction("hsn",product.hsn)
  //   updateFunction("unit",product.unit)
  //   updateFunction("taxSlab",Number(product.gst))
  // }

  const updateFunction = (key,value) =>{
		let updateObject = {...updated};
		updateObject["content"][key] = value;
		setUpdated(updateObject);
	}
  
  return (
    <Modal
      show={true}
      des="Edit line item quantity"
      title={"Edit Line Item"}
      style={{
        save: {
          background: "var(--primary-color)",
          border: "2px solid var(--primary-color)",
          padding: "8px 15px",
          width: "100%",
          color: "white",
          curor: "pointer",
          textAlign: "center",
        },
      }}
      buttonName="Update"
      close={close}
      onSave={() => {
        let obj = {
           itemId:edit.id,
          ...updated["content"]
        }
        if(Object.keys(updated["content"]).length) {
          onSave(obj)
        } 
      }}
    >
        <Select
            disabled={disableUpdate}
            value={singleService}
            items={services}
            placeholder="Select Service"
            select={(service) => {
               updateFunction("serviceId",service.value || null)
              setSingleService(service)
            }}
          />
       <ProductSearch
        disabled={disableUpdate}
        services={searchProductByService && singleService && singleService.label}
        placeholder={"Name"}
         ref={NAME} 
         categoryId={categoryId}
         onChangeText={(item) => {
          item && item.id &&  updateFunction("productId",item.id)
          item && item.title &&  updateFunction("name",item.title)
          if(item && item.glCode){
            updateFunction("gl",item.glCode)
            GLCODE.current.value=item.glCode
          }
         }}
         />
          <FunctionalInput
            disabled={disableUpdate}
            type="text"
            title="Material Code (HSN/SAC)"
            onChange={(e) =>{
              updateFunction("hsn",e.target.value)
            }}
             ref={MATERIAL}
          />
          <FunctionalInput 
            disabled={disableUpdate}
            ref={GLCODE} 
            title="GL Code" 
            onChange={(e) =>{
              updateFunction("gl",e.target.value)
            }}
          />
          <FunctionalInput
            disabled={disableUpdate}
            type="text"
            title="Unit"
            onChange={(e) =>{
              updateFunction("unit",e.target.value)
            }}
             ref={UNIT}
          />
      <FunctionalInput 
        disabled={_.includes(categoryList,category) || disableUpdate}
        ref={QTY} 
        title="Enter Quantity" 
        onChange={(e) =>{
          updateFunction("qty",Number(e.target.value))
        }}
        type="number"
        />
      {
        <>
          <FunctionalInput
           disabled={disableUpdate}
           ref={PRICE}
           title="Enter Price"
            type="number"
            onChange={(e) =>{
              updateFunction("price",Number(e.target.value))
            }} 
            />
          <SelectGST defaultValue={gst} placeholder="Select Gst" onSelect={(data) =>{
              updateFunction("taxSlab",Number(data.value))
              setGst(data)
            }}/>
          {((tdsAtLineItem && !contractId) || (contractId && tdsAtLineItemInContract)) &&
            <div style={{ width: '100%', paddingLeft: '10px', marginTop: 5 }}>
              <TdsLineItem 
                 disabled={disableUpdate}
                 vendorId={vendorId}
                 onSelect={(obj) =>  updateFunction("tds",obj)}
                 value={edit.tds ? edit.tds:null}
              />
            </div>
          }
          {!isCreditNote && <div style={{
            marginTop: 20
          }}>
            <ToggleSwitch disabled={disableUpdate} status={toggle} 
              callback={(val) =>{
              updateFunction("rcm_item",val)
              setToggle(val)
              }} label='RCM Invoice' />
          </div>}
        </>
      }
    </Modal>
  );
};

export default LineItemModal;
