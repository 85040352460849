import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom'
import {ToastContainer} from "../Common/fToast/ToastContainer";
import Fullscreen from "react-full-screen";
import {Sidebar} from "../Sidebar";
import SidebarMini from "../SidebarMini";
import connect from "react-redux/es/connect/connect";
import NotFound from "../Common/NotFound";
import {PageLoader} from "../Common/Components/PageLoader";
import {asyncComponent} from "../../Utils/Utils";
import _ from 'lodash'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {Transactiondetails} from '../Common/TransactionDetails'
import Arrow from '../SidebarMini/arrow.png'
import ReactTooltip from "react-tooltip";
import {useHistory,useLocation} from 'react-router-dom'
import PoDetails from "./fVendor/fProcurement/fPoDetails/PoDetails";
import Header from "../Header";
// import {useScriptConfig} from "../Hooks/ScriptConfig";
import {sendEvent} from "../../Utils/PostEvent";
import Marketplace from './fMarketplace';
import UserApp from './fMarketplace/Installed/UserApp';
import DashboardApp from './fMarketplace/Installed/DashboardApp';
import { useUrlConfig } from '../Hooks/UrlConfig';
import PackageJson from '../../../package.json';
import {set, get } from '../../Utils/Crypto';
import { useSelector} from 'react-redux';

function Wrapper(props) {
	let [fullScreen,setFullScreen] = useState(false);
	let [expanded,setExpanded] = useState(false);
	let HISTORY = useHistory()
	let LOCATION = useLocation();
	let URLCONFIG = useUrlConfig();
	let {diceNotificationBanner,diceNotificationBannerCriticality} = useSelector(state=>state.auth.config);
	
	useEffect(()=>{
		sendEvent('events.location',LOCATION.pathname);
		URLCONFIG.setUrl(LOCATION.pathname);
	},[LOCATION])

	return (
		<Fullscreen
			enabled={fullScreen}
			onChange={isFull =>{
				setFullScreen(isFull)
			}}
			
		>
			<div id="dashboard">
				<ToastContainer />
				<SidebarMini className={expanded?'sideBarMini-visible':''} config={props.config}  history={HISTORY} clicked={expanded} />
				<Sidebar className={expanded?'sideBar-hidden':''} config={props.config} history={HISTORY} clicked={expanded} />
				<div id="aside" className={expanded?`mini`:`expanded`}>
					{
						diceNotificationBanner.trim().length>0 ?
						<div style={{
							height:23,
							background: diceNotificationBannerCriticality? '#ff6666' :'#ff944d',
							zIndex:1111,
							position:'fixed',
							left:!expanded?255:0,
							top:0,
							right:0,
							fontSize:13,
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							color:"#fff",
							marginBottom:30
						}}>
							{diceNotificationBanner}
						</div>
						:null

					}
					
					<Header history={HISTORY}/>
					{props.children}
				</div>
				<div className='sidebar_handle' style={expanded?{transform: 'rotate(180deg)', left: '100px'}:{transform: 'none', left: '260px'}} onClick={()=>{
					setExpanded(!expanded)
				}}>
					<img src={Arrow} style={{width: '5px'}} alt="Arrow"/>
				</div>
			</div>
			<ReactTooltip/>
		</Fullscreen>
	)
}

function Pager(props) {
	let modules = props.config.modules;
	let isSuper = props.isSuper;
	let version = get('version');
	let allViews = []
	//let Config = useScriptConfig();
	useEffect(()=>{
		if(props.config.name){
			//Config.load()
		}
	},[props.config.name])


	useEffect(()=>{
		console.log(version,'---version',PackageJson.version)
		if(version!==PackageJson.version) {
			if('caches' in window){
				caches.keys().then((names) => {
			   names.forEach(name => {
				   caches.delete(name);
			   })
				})
			}
			window.location.reload(true)
				
		}
		set('version',PackageJson.version)
		
	},[])

	if (!props.config.name) {
		return (
			<PageLoader />
		)
	}
	allViews.push(<Route key="home"  component={asyncComponent(()=>import('./HomeN'))} path="/app/home" />)
	allViews.push(<Route key="home" exact={true} component={asyncComponent(()=>import('./HomeN'))} path="/app/home/new" />)
	allViews.push(<Route key="trans" exact={true} component={Transactiondetails} path="/app/transaction/:id" />)
	if(props.config && props.config.modules && props.config.modules.length && !props.config.modules.includes("Organisation") && props.config.plugins && props.config.plugins.hideOrganisation){
		allViews.push(<Route key="org" component={asyncComponent(() => import('../Common/NotFound'))} path="/app/organisation" />) 
	}
	else{
		allViews.push(<Route key="org" component={asyncComponent(() => import('./fMain'))} path="/app/organisation" />) 
	}
	allViews.push(<Route key="settings" component={asyncComponent(() => import('./Others/fAuthentication'))} path="/app/settings/password"  />)
	allViews.push(<Route key="others" component={asyncComponent(() => import('./Others'))} path="/app/others" />)
	allViews.push(<Route key="approvals" component={asyncComponent(() => import('./fApprovals'))} path="/app/approvals" />)
	if (props.config.isSuper || props.config.isRoot) {
		allViews.push(<Route key="programmes" exact component={asyncComponent(() => import('./fControl'))} path="/app/programmes" />)
		allViews.push(<Route key="tower" component={asyncComponent(() => import('./fControl'))} path="/app/tower" />)
		allViews.push(<Route key="plugin" component={asyncComponent(() => import('./fControl'))} path="/app/settings/plugins"  />)
		allViews.push(<Route key="control" component={asyncComponent(() => import('./fControl/route'))} path="/app/control" />)
		allViews.push(<Route key="programmes" component={asyncComponent(() => import('./fControl/route'))} path="/app/programmes" />)
	}
	
	if (_.includes(modules, "TRAVEL DESK") || _.includes(modules, "SELF BOOKINGS")) {
		allViews.push(<Route key="travel" component={asyncComponent(() => import('./fTravel'))}
							 path="/app/travel" />)
	}
	allViews.push(<Route key="workspace" component={asyncComponent(() => import('./fWorkspace'), { config:props.config })} path="/app/workspace" />)
	allViews.push(<Route key="advances" component={asyncComponent(() => import('./fAdvances/route'))} path="/app/advances" />)
	if(_.includes(modules,'Expenses') || isSuper) {
		allViews.push(<Route key="expenses" component={asyncComponent(() => import('./fExpenses'))} path="/app/expenses" />)
	}
	if (_.includes(modules, 'UTILITYPAYMENTS')) {
		allViews.push(<Route key="utility" component={asyncComponent(() => import('./fUtility'))} path="/app/utility" />)
	}
	if (_.includes(modules, "UTILITYPAYMENTS") || _.includes(modules, "VENDOR") ||  _.includes(modules, "CUSTOMERS")) {
		allViews.push(<Route key="vendor" component={asyncComponent(() => import('./fVendor'))} path="/app/vendor" />)
		allViews.push(<Route key="vendorpath" component={asyncComponent(()=>import('./fVendor/fInvoices'))} path="/app/banking/invoice/:id" />)
		allViews.push(<Route key="vendorpath" component={asyncComponent(()=>import('./fVendor/fInvoices'))} path="/app/banking/invoices" />)
		allViews.push(<Route key="vendor" component={PoDetails} path="/app/banking/po/:id/details" />)
	}

	allViews.push(<Route key="plugins" component={UserApp} path="/app/user/:appId"/>)
	allViews.push(<Route key="plugins" component={DashboardApp} path="/app/dashboard/:appId"/>)
	allViews.push(<Route key="plugins" component={asyncComponent(()=>import('./fBudget'))} path="/app/budget"/>)
	allViews.push(<Route key="plugins" component={Marketplace} path="/app/marketplace"/>)

	allViews.push(<Route key="plugins" component={asyncComponent(()=>import('./fThirdParty'))} path="/app/apps" />)
	allViews.push(<Route key="plugins" component={asyncComponent(()=>import('./fThirdParty'))} path="/app/injects" />)

	
	return (
		<Wrapper {...props}>
			<Switch>
				{allViews}
				<NotFound/>
			</Switch>
		</Wrapper>
	)
}


const PagerConnected = connect(state => {
	return {
		config: state.auth.config
	}
})(Pager)

export default PagerConnected;
