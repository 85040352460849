import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {  FloatSelect } from '../../../../../Common/Class/FloatLabelInput';
import { FunctionalInput } from '../../../../../Common/fInputs';
import { Modal } from '../../../../../Common/fModal/Modal';
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import SearchEntity from './SearchEntity';
import SearchOffice from './SearchOffice';
import SearchFilterLayer from './SearchFilterLayer';


const CostAllocation = forwardRef(({totalAmount,prevItems,costAllocationType,hide,gstin=undefined,category=undefined},ref) => {



    const cType = useSelector(state=>state.auth.config.costAllocationType);
    const [type,setType] = useState(costAllocationType?costAllocationType:{key:cType,value:cType});
    const [add,setAdd] = useState(false);
    const [items,setItems] = useState([]);
    const [filterLayer, setFilterLayer] = useState(undefined);
    const companyList = ['ANRCK']
    let tenant = useSelector(state=>state.auth.config.tenant);

    let AMOUNT = useRef(),PERCENTAGE=useRef(),LOCATION=useRef(),FILTER_LAYER= useRef();
    let {costAllocation} = useSelector(state=>state.auth.config.plugins);



    useEffect(()=>{
        if(prevItems && prevItems.length && costAllocationType) {
           const value=  calculateItems(prevItems.filter(item=>item.costAllocations && item.costAllocations.length),totalAmount);
           setItems(value)
        }
    },[prevItems,totalAmount,costAllocationType])

   

    const calculateItems = (prevItems, totalAmount) => {
        const items = [];
        totalAmount = + (totalAmount.toFixed(2));    // make amendment on 13sept due to issue between three digit and 2 digit
    
      
        // Iterate over each item in prevItems
        prevItems.forEach((item) => {
          // Iterate over costAllocations in each item
          item.costAllocations.forEach((allocation) => {
            // Check if the location already exists in items array
            const existingItem = items.find((i) => i.location === allocation.location);
      
            if (existingItem) {
              // If the location already exists, update the ratio and amount
              existingItem.ratio += allocation.ratio;
              existingItem.amount += allocation.amount;
            } else {
              // If the location doesn't exist, create a new item
              items.push({
                location: allocation.location,
                ratio: allocation.ratio,
                amount: allocation.amount
              });
            }
          });
        });
      
        // Calculate the ratio and amount based on the totalAmount
        items.forEach((item) => {
            item.ratio = +((item.amount/totalAmount)*100)
        });

      
        return items;
      };
      


    useImperativeHandle(ref,()=>({
        getValue:()=>{

            let totalPercentage = items.reduce((acc, item) => acc + item.ratio, 0);
            let dummyPercentage = items.reduce((acc, item) => +parseFloat(acc + item.ratio).toFixed(2), 0);
    
 
            let tAmount = items.reduce((acc, item) => acc + item.amount, 0);
            let dummyAmount = 0
            items.forEach(i=>{
                let sItem = +(i.amount).toFixed(3);
                dummyAmount += sItem;
            })
            dummyAmount = +(dummyAmount).toFixed(3)


            tAmount = Number(tAmount.toFixed(3));
            totalAmount  = Number(totalAmount.toFixed(3));
            totalPercentage = Number(totalPercentage.toFixed(2));

            console.log("--total cost allocation",totalPercentage,dummyPercentage,tAmount,dummyAmount,totalAmount)

            if(!costAllocation) {
                return true;
            }
            
            if(items.length===0) {
                return true;
            } 


            if(totalPercentage!==100.00) {
                showToast({
                    type:'error',
                    message:'Combine cost allocation must be 100%'
                })
                return false;
            }


            

           
            

            
            if (+Math.abs(dummyPercentage - 100).toFixed(2) === 0.01) {

                let isGreaterError = +Math.abs(dummyAmount - totalAmount).toFixed(2) === 0.01


                const adjustedItems = items.map((item,index) => {
                    if(index===0) {
                        return {
                            ...item,
                            amount: isGreaterError ?  +(item.amount-0.01).toFixed(2): +(item.amount).toFixed(2),
                            ratio: +(item.ratio-0.01).toFixed(2)
                        }
                    } else {
                        return {
                            ...item,
                            amount:+(item.amount).toFixed(2),
                            ratio:+(item.ratio).toFixed(2)
                        }
                    }}
            
                  );

                  return {
                    costAllocationBaseType: type.key,
                    costAllocationAmount: Number(parseFloat(totalAmount).toFixed(2)),
                    costAllocations: adjustedItems,
                  };
            
               
              }

              if (+Math.abs(dummyAmount - totalAmount).toFixed(2) === 0.01) {
                // Adjust line item amounts by adding 0.01 equally
              
          
                const adjustedItems = items.map((item,index) => {
                    if(index===0) {
                        return {
                            ...item,
                            amount: +(item.amount-0.01).toFixed(2),
                            ratio: +(item.ratio).toFixed(2)
                        }
                    } else {
                        return {
                            ...item,
                            amount:+(item.amount).toFixed(2),
                            ratio:+(item.ratio).toFixed(2)
                        }
                    }}
            
                  );

                return {
                  costAllocationBaseType: type.key,
                  costAllocationAmount: Number(parseFloat(totalAmount).toFixed(3)),
                  costAllocations: adjustedItems,
                };
              }


              if(tAmount!==totalAmount) {
                showToast({
                    type:'error',
                    message:'Combine amount value must be equal to totalAmount of invoice'
                })
                return false;
            }



            return {
                    costAllocationBaseType:type.key,
                    costAllocationAmount:Number(parseFloat(totalAmount).toFixed(2)),
                    costAllocations:[...items.map(item=>({
                        ...item,
                        amount:+item.amount.toFixed(3),
                        ratio:+item.ratio.toFixed(2)
                    }))]
            }
        }
    }))

    if(!costAllocation) {
        return <></>
    }


    return(
        <div style={{marginBottom:30,
            ...hide ?
            {
                display:'none'
            }
            :
            {

            }
        }}>
            <div style={{fontSize: '14px', fontWeight: 'bold', margin: '10px 0px'}}>Cost Allocation</div>   
            {
                items.length>0 || true ?
                <>
                    <div style={{height:40,background:'#dedede',border:'1px solid var(--border-color)',display:'flex',borderTopLeftRadius:4,borderTopRightRadius:4}}>
                    {
                     [
                        {name:'location',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        {name:'Percentage',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        {name:'Amount',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        {name:'Action',flex:1,style:{fontWeight:'bold'}}
                     ]
                     .map((item,index)=>{
                        return(
                            <div key={index} style={{flex:item.flex,...item.style,height:40,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                {item.name}
                            </div>
                        )
                     })
                     }
                   </div>
                   {
                    items.map((item,index)=>{
                        return(
                            <div key={index} style={{height:40,border:'1px solid var(--border-color)',display:'flex',flex:1}}>
                                <div style={{height:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.location}</div>
                                <div style={{height:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.ratio?item.ratio.toFixed(2):item.ratio}</div>
                                <div style={{height:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.amount?item.amount.toFixed(3):item.amount}</div>
                                <div onClick={()=>{
                                    let temp = [...items];
                                    temp = temp.filter(i=>i.location!==item.location);
                                    setItems(temp);
                                }} style={{height:40,display:'flex',flex:1,borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',color:'var(--red-color)',cursor:'pointer'}}>Delete</div>

                            </div>  
                        )
                    })
                   }

                </>
                :null
            }
            <p style={{backgroundColor:'var(--green-color)',color:'#fff',height:25,width:25,borderRadius:'50%',fontWeight:'bold',cursor:'pointer',marginTop:10,fontSize:16,display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setAdd(true)}}>+</p>

            {
                add ?
                <Modal
                    title="Add Item"
                    show={true}
                    buttonName="Add"
                    style={{
                        save:{
                            display:'flex',
                            height:40,
                            alignItems:'center',
                            justifyContent:'center',
                            borderRadius:4
                        }
                    }}
                    close={()=>{
                        setAdd(false);
                    }}
                    onSave={()=>{
                        let percentage = PERCENTAGE.current.value;
                        let amount = AMOUNT.current.value;
                        let location = LOCATION.current.getValue();

                        if(!location) {
                            showToast({
                                type:'error',
                                message:"Please select the location"
                            })
                            return;
                        }

                        if(!percentage && !amount) {
                            showToast({
                                type:'error',
                                message:"Please enter the percentage or amount"
                            })
                            return;
                        }
            
                        let data = {
                            ratio:+parseFloat(percentage || amount / totalAmount * 100).toFixed(2),
                            amount: +parseFloat(amount || totalAmount * percentage / 100).toFixed(3),
                            location:`${location.name}`
                        }

                        let sItems = [...items];
                        sItems.push(data);
                        setItems(sItems);

                        let totalPercentage = sItems.reduce((acc, item) => acc + item.ratio, 0);
                        let tAmount = sItems.reduce((acc, item) => acc + item.amount, 0);



                        // Check if the total percentage exceeds 100
                        if (totalPercentage > 100) {
                            showToast({
                                type: 'error',
                                message: 'Total percentage cannot exceed 100%'
                            })
                            sItems.pop(); // Remove the last item from the list
                            setItems(sItems);
                            return;
                        }
                    
                        // Check if the total amount exceeds the props totalAmount
                        if (tAmount > totalAmount) {
                            showToast({
                                type: 'error',
                                message: 'Total amount cannot exceed the total amount'
                            })
                            sItems.pop(); // Remove the last item from the list
                            setItems(sItems);
                            return;
                        }

                        setAdd(false);
                    
                    }}
                >
                     <SearchFilterLayer 
                       ref={FILTER_LAYER} 
                      onSelect={setFilterLayer}
                      filterLayer={filterLayer}
                     />
                    <FloatSelect
                        title="Select Type"
                        blue="** All cost allocation define on the type you select on the first time."
                        items={[
                            {key:cType,value:cType},
                            ...!companyList.includes(tenant)?
                            [{key:'office',value:'Office'},
                            {key:'dept',value:'Department'},
                            {key:'team',value:'Team'}]:[],
                        ]}
                        onChange={(value)=>{
                            setType({
                                key:value,
                                value
                            })
                        }}
                        value={type}
                        disabled={items.length}
                    />

                    {
                        type.key==='office' ?
                        <SearchOffice ref={LOCATION} /> :
                        type.key === cType ?
                        <SearchEntity category={category} gstin={gstin} ref={LOCATION}  extra={filterLayer ? {data:filterLayer}:{}} />
                        :null
                    }

                    <div style={{display:'flex',flex:1}}>
                         <FunctionalInput
                            type="number" 
                            title="Percentage" 
                            placeholder="Enter the percentage" 
                            ref={PERCENTAGE} 
                            style={{marginRight:20}}
                            onChange={(e)=>{
                                let value = +e.target.value;
                                 if(value>100) {
                                     PERCENTAGE.current.value = 0;
                                     showToast({
                                            type:'error',
                                             message:'Percentage value not more than 100%'
                                    })
                                    return;
                                 }

                                 let currentAmount = (value*totalAmount)/100
                                AMOUNT.current.value = currentAmount;

                             }}
                         />
                          <FunctionalInput 
                                type="number" 
                                title="Amount"
                                placeholder="Enter the amount" 
                                ref={AMOUNT} 
                                onChange={(e)=>{
                                        let value = +e.target.value;
                                        let currentPercentage = ((parseInt(value)*100)/(totalAmount));
                                        PERCENTAGE.current.value = currentPercentage;
                                 }}
                        
                        />
                    </div>


                   

                   

                </Modal>
                :null
            }
        </div>
    )
})

CostAllocation.defaultProps = {
    prevItems:[],
    costAllocationType:undefined,
    hide:false
}


export {CostAllocation}