import React from 'react';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';


class Index extends React.Component {



    header = () => {
        return (
            <div style={{
                display: 'flex',
                height: 45,
                flex: 1,
                borderBottom: '1px solid var(--border-color)',
                fontWeight: 700
            }}>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    S. NO.
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    DESCRIPTION
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    HSN/SAC <br/>CODE
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    QTY
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    Unit
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    Rate
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    TOTAL
                </div>
            </div>
        )
    }

    mapItem = (item, index) => {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                height: 50
            }} key={index}>

                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {index + 1}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {item.name}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {item.hsn}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {item.qtty}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {item.unit}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {numberWithCommas(item.price.toFixed(2))}
                </div>
                <div style={{
                    flex: 1,
                    borderRight: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                  {this.props.currency} {" "}   {numberWithCommas(item.base.toFixed(2))}
                </div>

            </div>
        )
    }

    render() {
        let { items } = this.props;
        let tax = 0;
        let totalAmount = 0;
        items.forEach(val => {
            tax += val.tax;
            totalAmount += val.amount
        })
        return (
            <div>
                {this.header()}
                {items.map(this.mapItem)}
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 4,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>Tax</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}> {this.props.currency} {" "} {numberWithCommas(tax.toFixed(2))}</div>
                </div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 4,
                        borderRight: '1px solid var(--border-color)'
                    }} />
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600
                    }}>Total Amount</div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}> {this.props.currency} {" "} {numberWithCommas(totalAmount.toFixed(2))}</div>
                </div>
            </div>
        )
    }
}

export default Index;
