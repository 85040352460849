import React, { useEffect, useState } from 'react';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/Components/PageLoader';
import { SidePane } from '../../../../Common/fSidePane/RightPane';
import TabSwtich from '../../../../Common/fTabSwitch';
import Details from './Details';
import TimeLine from './TimeLine';
import _ from 'lodash'
import { Integration } from '../Integration';

const VendorAdvanceDetails = (props) => {


    const [data,setData] = useState({});
    const [loading,setLoading] = useState(true);
    const [getTab, setTab] = useState(1);
    const [categoriesMapping,setCategoriesMapping] = useState({});

    useEffect(()=>{
        get('/vendor/config',(e,r)=>{
            if(r){
                let obj={}
                r.items.forEach((item)=>{
                    obj[item.id]=item.name
                })
                setCategoriesMapping(obj);
            }
        })
    },[])

    useEffect(()=>{
        if(props.data) {
            init()
        }
       
    },[props.data])

  const init = () => {
    get(`vendor/advances/${props.data.id}/details`,(e,r)=>{
        if(r) {
            setData(r);
            setLoading(false);
        }
    })
  }
    
    let tabs = [
		{
			name: 'Details', active: getTab === 1 , onClick: () => {
				setTab(1)
			}
		},
        ...(data.content && data.content.integrationData && data.content.integrationData &&  _.size(data.content.integrationData)>0)  ?
		[
			{
				name: 'Integration', active: getTab === 3, onClick: () => {
					setTab(3)
				}
			}
		] : [],
		{
			name: 'TimeLine', active: getTab === 2 , onClick: () => {
				setTab(2)
			}
		},
		
	]

    
    if(loading) {
        return <SidePane button={false} onClose={()=>props.onClose(false)}>
                <PageLoader />
        </SidePane>
    }

    return(
        <SidePane button={false} onClose={()=>props.onClose(false)}>
        <div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
        <div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}} >Vendor Advance</div>
        <div style={{color: 'var(--text-color)',marginBottom: '35px', fontWeight: 600, fontSize: '24px', lineHeight: '1.3'}}>All Vendor Advance Details</div>
        <TabSwtich tabs={tabs} />

        <div className='FadeRight-Effect'>
            {
                getTab===1?<Details categoriesMapping={categoriesMapping} reload={init} id={props.data.id} data={data} pending={props.pending} buttons={props.button} loading={props.buttonLoading}/>:null
            }
            {
                getTab===2?<TimeLine timelines={data.timelines} />:null
                    }
                    {getTab === 3 ?
                        data && data.content.integrationData && data.content.integrationData && _.size(data.content.integrationData) > 0 ?
                            <Integration
                                type="vendor-advance"
                                reference={props.data.id}
                                integrationData={data.content.integrationData}
                                refresh={init}
                            />
                            : null
                        : null}
        </div>
        </div>
    </SidePane>   
    )
}

VendorAdvanceDetails.defaultProps = {
    pending:false,
    button:[]
}

export default VendorAdvanceDetails;
export {VendorAdvanceDetails}