import React, { useMemo, useState } from 'react'
import { TableContainer } from '../../../Common/fContainers/TableContainer'
import { Tile } from '../../../Common/fCard/Tile';
import ReactJson from 'react-json-view'
import { SidePane } from '../../../Common/fSidePane/RightPane';
import { Spinner } from '../../../Common/fLoader';
import { handleError } from '../../../Common/fToast/ToastCallback';

function LogCards({ logs }) {
    useMemo(()=>{
        logs.reverse();
    },[])
    const [view,setView] = useState(false)
    const [data,setData] = useState([])

    const fetchJsonData = (item) => {
        setView(item)
        fetch(item.value).then((res)=>res.json()).then((data)=>{
            setData(data);
        }).catch(err => {
            handleError(err)
        });
    }

    const mapCard = (item, index) => {
        let splittedArr = item.key.split("_");
        let badgeData = splittedArr[0];
        let Title = splittedArr.slice(2, 5).join("-") + " " + splittedArr[5] + " HH";
        return (
            <Tile onClick={()=>{fetchJsonData(item)}} key={index} title={Title} badge={badgeData} style={{
                width:290
            }}>
            </Tile>
        )
    }
    
    return (
        <div>
            <p style={{
                fontSize: 15,
                fontWeight: 600,
                color: "var(--text-color)",
                marginTop: 40,
                marginBottom: 25
            }}>
                Logged Files
            </p>
            <div style={{ "display": "flex", "flex-wrap": "wrap",}}>
                <TableContainer title="Files" items={logs} mapCard={mapCard} />
            </div>
            {view &&
                <SidePane onClose={() => setView(false)} button={false}>
                    {data.length > 0 ?
                        <>
                            <button onClick={() => { window.open(view.value) }}>Download</button>
                            {data.map((item, index) => {
                                return (
                                    <ReactJson
                                        key={index}
                                        src={item}
                                        collapsed={false}
                                        enableClipboard={false}
                                        displayDataTypes={false}
                                        style={{ padding: '1rem', borderRadius: '8px', backgroundColor: '#f5f5f5', marginBottom: "1rem", marginTop: "2rem" }}
                                    />
                                )
                            })}
                        </>
                        : <Spinner />}
                </SidePane>
            }
        </div>
    )
}

export default LogCards